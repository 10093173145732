import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import directoryService from './directoryService'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
  directoryList: [],
  myDirectory: null,
  myDirectoryInitialState: null,
  isDirectoryError: false,
  isDirectorySuccess: false,
  isDirectoryLoading: false,

  directoryStatus: null,
  isDirectoryStatusLoading: false,
  isDirectoryStatusSuccess: false,
  isDirectoryStatusError: false,

  isDirectoryCreatedSuccess: false,
  isDirectoryUpdatedSuccess: false,
  isDirectoryUpdatedLoading: false,
  isDirectoryCreatedLoading: false,

  isDirectoryDeletedSuccess: false,
  isDirectoryDeletedLoading: false,
  isDirectoryDeletedError: false,

  isAddToLoading: false,
  isAddToSuccess: false,
  isAddToError: false,
  addToErrorMessage: '',

  isSearchLoading: false,
  isSearchError: false,
  isSearchSuccess: false,
  isSearchActive: false,
  searchTerm: '',
  searchList: [],

  totalEntries: 0,
  totalPages: 0,
  hasPrevPage: false,
  nextPage:null,
  currentPage: 1,
  prevPage: null,  
  hasNextPage: false,
  hasFilter: false,
  filterValue: null,
  hasSortValue: null,
  
  // this sort object is used to determing the sort value to send to the backend
  // sort object is NOT modified by the user
  
  sortLabels: {
    nameAZ: 'Name A-Z',
    nameZA: 'Name Z-A',
    dateNewestFirst: 'Newest First',
    dateOldestFirst: 'Oldest First',
    familySizeLargestFirst: 'Family Size',
    familySizeSmallestFirst: 'Family Size',
  },

  // this is all for the sake of a simplified UI and UX
  // instead of having a SORT dropdown with 6 options
  // we have a dropdown with 3 options and each of those options has ascending or descenfing order

  sortArrays: {
    name: [ "nameAZ", "nameZA" ],
    date: [ "dateNewestFirst", "dateOldestFirst" ],
    familySize: [ "familySizeLargestFirst", "familySizeSmallestFirst" ],
  },

  sortValue: 'nameAZ',
  pagesArray: [],
  tempPlaceholder: null,

  directoryMessage: '',
  isNewPageLoading: false,

  
  birthdays: [],
  viewBirthdays: false,
  getBirthdaysLoading: false,
  getBirthdaysSuccess: false,
  getBirthdaysError: false,


}


// get directory in church profile page
export const getDirectory = createAsyncThunk(
  'directory/getDirectory',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.getDirectory(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// get directory in church profile page
export const addMyDirectory = createAsyncThunk(
  'directory/addMyDirectory',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.addMyDirectory(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// get directory in church profile page
export const getMyDirectory = createAsyncThunk(
  'directory/getMyDirectory',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.getMyDirectory(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Create new directory item
export const createDirectoryItem = createAsyncThunk(
  'directory/createDirItem',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.createDirectoryItem(data, token)
    } catch (error) {
        const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// delete directory item
export const deleteDirectoryItem = createAsyncThunk(
  'directory/delete',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.deleteDirectoryItem(data, token)
    } catch (error) {
        const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// delete directory item
export const updateDirectoryItem = createAsyncThunk(
  'directory/update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.updateDirectoryItem(data, token)
    } catch (error) {
        const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// search
export const searchDirectory = createAsyncThunk(
  'directory/search',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.searchDirectory(data, token)
    } catch (error) {
        const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getDirectoryStatus = createAsyncThunk(
  'directory/getDirectoryStatus',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.getDirectoryStatus(data, token)
    } catch (error) {

        const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getBirthdays = createAsyncThunk(
  'directory/getBirthdays',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await directoryService.getBirthdays(data, token)
    } catch (error) {


        const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const directorySlice = createSlice({
    name: 'directory',
    initialState,
    reducers: {
        resetDirectory: (state) => initialState,
        resetSearchTerm: (state) => {
          state.searchTerm = ''
          state.isSearchActive = false
          state.directoryList = state.tempPlaceholder
        },
        setSearchTerm: (state, action) => {
          state.searchTerm = action.payload
        },
        addFamilyMemberToSettingsUpdate: (state, action) => {
          state.myDirectory.familyList.push(action.payload.familyData)
        },
        editFamilyMemberSettingsUpdate: (state, action) => {
          state.myDirectory.familyList[action.payload.index] = action.payload.familyData
        },
        removePersonFromEditingDirectory: (state, action) => {
          state.myDirectory.familyList.splice(action.payload.index, action.payload.index-1)
        },
        discardChanges: (state, action) => {
          state.myDirectory.familyList = state.myDirectoryInitialState.familyList
          state.myDirectory.name = state.myDirectoryInitialState.name
        },
        setNewPageLoading: (state, action) => {
          state.isNewPageLoading = action.payload
        },
        setSortValue: (state, action) => {
          state.sortValue = action.payload
        },
        setViewBirthdays: (state, action) => {
          state.viewBirthdays = action.payload
        },
        resetDirectoryModal: (state) => {
          state.isDirectoryCreatedSuccess = false
        }

    },
    extraReducers: (builder) => {
      builder
        .addCase(getDirectory.pending, (state) => {
          state.isDirectoryLoading = true
        })
        .addCase(getDirectory.fulfilled, (state, action) => {
          state.isDirectoryLoading = false
          state.isDirectorySuccess = true

          state.directoryList = action.payload.directories

          let emptyArray = []
          // create array with total pages here
          for(let i=0;i<action.payload.totalPages; i++){
            emptyArray.push(i+1)
            state.pagesArray = emptyArray
            console.log(JSON.stringify(state.pagesArray))
          }

          state.totalPages = action.payload.totalPages
          state.totalEntries = action.payload.totalEntries
          state.hasPrevPage = action.payload.hasPreviousPage
          state.nextPage = action.payload.nextPage
          state.currentPage = action.payload.pageNumber
          state.prevPage = action.payload.prevPage
          state.hasNextPage = action.payload.hasNextPage
          state.isNewPageLoading = false
        })
        .addCase(getDirectory.rejected, (state, action) => {
          state.isDirectoryLoading = false
          state.isDirectoryError = true
          state.directoryMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getMyDirectory.pending, (state) => {
          state.isDirectoryLoading = true
        })
        .addCase(getMyDirectory.fulfilled, (state, action) => {
          state.isDirectoryLoading = false
          state.isDirectorySuccess = true
          state.myDirectory = action.payload
          state.myDirectoryInitialState = action.payload // used as a duplicate to revert to when user clicks discard changes
        })
        .addCase(getMyDirectory.rejected, (state, action) => {
          state.isDirectoryLoading = false
          state.isDirectoryError = true
          state.directoryMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(addMyDirectory.pending, (state) => {
          state.isAddToLoading = true
        })
        .addCase(addMyDirectory.fulfilled, (state, action) => {
          state.isAddToLoading = false
          state.isAddToSuccess = true
          state.directoryStatus = 'present'
          state.directoryList.unshift(action.payload)
        })
        .addCase(addMyDirectory.rejected, (state, action) => {
          state.isAddToLoading = false
          state.isAddToError = true
          state.addToErrorMessage = action.payload
        })
        ///%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(createDirectoryItem.pending, (state) => {
          state.isDirectoryCreatedLoading = true
        })
        .addCase(createDirectoryItem.fulfilled, (state, action) => {
          state.isDirectoryCreatedLoading = false
          state.isDirectoryCreatedSuccess = true
          state.directoryList.unshift(action.payload.directory)
        })
        .addCase(createDirectoryItem.rejected, (state, action) => {
          state.isDirectoryLoading = false
          state.isDirectoryError = true
          state.directoryMessage = action.payload
        })

        ///%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(deleteDirectoryItem.pending, (state) => {
          state.isDirectoryDeletedLoading = true
        })
        .addCase(deleteDirectoryItem.fulfilled, (state, action) => {
          state.isDirectoryDeletedSuccess = true
          state.directoryList = state.directoryList.filter((item) => item._id !== action.payload.directoryId)
        })
        .addCase(deleteDirectoryItem.rejected, (state, action) => {
          state.isDirectoryDeletedLoading = false
          state.isDirectoryError = true
          state.directoryMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(updateDirectoryItem.pending, (state) => {
          state.isDirectoryUpdatedLoading = true
        })
        .addCase(updateDirectoryItem.fulfilled, (state, action) => {
          state.isDirectoryUpdatedLoading = false
          state.isDirectoryUpdatedSuccess = true

          if(action.payload.isEditing){
            let index = state.directoryList.findIndex((item) => item._id === action.payload.directory._id)

            state.directoryList[index] = action.payload.directory

          }

          // get modal state from directoryModal slice





        })
        .addCase(updateDirectoryItem.rejected, (state, action) => {
          state.isDirectoryLoading = false
          state.isDirectoryError = true
          state.directoryMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(searchDirectory.pending, (state) => {
          state.isSearchLoading = true

        })
        .addCase(searchDirectory.fulfilled, (state, action) => {
          state.isSearchSuccess = true
          state.isSearchLoading = false
          state.isSearchActive = true
          
          if(!state.tempPlaceholder){
            state.tempPlaceholder = state.directoryList
          }
          state.directoryList = action.payload
        })
        .addCase(searchDirectory.rejected, (state, action) => {
          state.isSearchError = true
          state.isSearchSuccess = false
          state.isSearchLoading = false
          state.isSearchActive = true
          state.directoryMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getDirectoryStatus.pending, (state) => {
          state.isDirectoryStatusLoading = true
        })
        .addCase(getDirectoryStatus.fulfilled, (state, action) => {
          state.isDirectoryStatusLoading = false
          state.isDirectoryStatusSuccess = true
          state.directoryStatus = action.payload.status
        })
        .addCase(getDirectoryStatus.rejected, (state, action) => {
          state.isDirectoryStatusLoading = false
          state.isDirectoryStatusError = true
          state.directoryMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getBirthdays.pending, (state) => {
          state.getBirthdaysLoading = true
        })
        .addCase(getBirthdays.fulfilled, (state, action) => {
          state.getBirthdaysLoading = false
          state.getBirthdaysSuccess = true
          state.birthdays = action.payload.upcomingBirthdays
        })
        .addCase(getBirthdays.rejected, (state, action) => {
          state.getBirthdaysLoading = false
          state.getBirthdaysError = true 
        })
  },
})

export const { 
  resetDirectory, resetSearchTerm, setSearchTerm, addFamilyMemberToSettingsUpdate,
  editFamilyMemberSettingsUpdate, removePersonFromEditingDirectory, discardChanges,
  setNewPageLoading, setSortValue, setViewBirthdays, resetDirectoryModal
} = directorySlice.actions
export default directorySlice.reducer
