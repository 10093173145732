
export const settingsLanguage = {
    settings: {
        en: 'Settings',
        es: 'Ajustes'
    },
    inviteMembers: {
        en: 'Invite Members',
        es: 'Invitar Miembros'
    },
    memberGiving: {
        en: 'Member Giving',
        es: 'Donaciones de Miembros'
    },
    members: {
        en: 'Members',
        es: 'Miembros'
    },
    memberships: {
        en: 'Memberships',
        es: 'Membresías'
    },
    requests: {
        en: 'Requests',
        es: 'Solicitudes'
    },
    profileImage: {
        en: 'Profile Image',
        es: 'Imagen de Perfil'
    },
    profile: {
        en: 'Profile',
        es: 'Perfil'
    },
    myDirectoryItem: {
        en: 'My Directory Item',
        es: 'Perfil de Directorio'
    },
    churchDirectory: {
        en: 'Church Directory',
        es: 'Directorio de Iglesia'
    },
    displayName: {
        en: 'Display Name',
        es: 'Nombre de Usuario'
    },
    username: {
        en: 'Church Username',
        es: 'Nombre de Usuario de Iglesia'
    },
    email: {
        en: 'Email',
        es: 'Correo Electrónico'
    },
    password: {
        en: 'Password',
        es: 'Contraseña'
    },
    billing: {
        en: 'Billing',
        es: 'Facturación'
    },
    premium: {
        en: 'Praysely Premium',
        es: 'Premium'
    },
    language: {
        en: 'Language',
        es: 'Idioma'
    },
    report: {
        en: 'Report',
        es: 'Reporter'
    },
    viewSettings: {
        en: 'View Settings',
        es: 'Ajustes Visuales'
    },
    requestsRecieved: {
        en: 'Requests Recieved',
        es: 'Solicitudes Recibidas'
    },
    noRequests: {
        en: 'No requests to show',
        es: 'No hay solicitudes para mostrar'
    },
    accept: {
        en: 'Accept',
        es: 'Aceptar'
    },
    reject: {
        en: 'Reject',
        es: 'Rechazar'
    },
    memberManagement: {
        en: 'Member Management',
        es: 'Gestión de Miembros'
    },
    requestsSent: {
        en: 'Requests Sent',
        es: 'Solicitudes Enviadas'
    },
    members: {
        en: 'Members',
        es: 'Miembros'
    },
    loadMore: {
        en: 'Load More',
        es: 'Cargar Más'
    },
    noMembers: {
        en: 'No members to show',
        es: 'No hay miembros para mostrar'
    },
    noSearchResults: {
        en: 'No search results',
        es: 'No hay resultados de búsqueda'
    },
    searchMembers: {
        en: 'Search Members',
        es: 'Buscar Miembros'
    },
    remove: {
        en: 'Remove',
        es: 'Eliminar'
    },
    fromMembership: {
        en: 'from membership',
        es: 'de la membresía'
    },
    removeModerator: {
        en: 'as a moderator',
        es: 'como moderador'
    },
    removeAdmin: {
        en: 'as an admin',
        es: 'como administrador'
    },
    make: {
        en: 'Make',
        es: 'Hacer'
    },
    admin: {
        en: 'admin',
        es: 'administrador'
    },
    moderator: {
        en: 'moderator',
        es: 'moderador'
    },
    removeUser: {
        en: 'Remove from church',
        es: 'Eliminar de la iglesia'
    },
    removeUserAdmin: {
        en: 'Remove from admin',
        es: 'Eliminar de administrador'
    },
    removeUserModerator: {
        en: 'Remove from moderator',
        es: 'Eliminar de moderador'
    },
    makeUserAdmin: {
        en: 'Make admin',
        es: 'Hacer administrador'
    },
    makeUserModerator: {
        en: 'Make moderator',
        es: 'Hacer moderador'
    },
    profileSettingsControl: {
        en: 'Profile Settings Control',
        es: 'Ajustes Visuales de Perfil'
    },
    publicView: {
        en: 'Public View',
        es: 'Vista Pública'
    },
    memberView: {
        en: 'Member View',
        es: 'Vista de Miembro'
    },
    publicViewDescription: {
        en: 'These settings control visible content to non logged in users and/or non member users - Potentially anyone on the internet. Click on the question item for more details about each item.',
        es: 'Estas configuraciones controlan el contenido visible para usuarios que no han iniciado sesión y/o usuarios que no son miembros, potencialmente cualquier persona en Internet. Haga clic en el elemento de la pregunta para obtener más detalles sobre cada elemento.'
    },
    profileImg:   {
        en: 'Profile Image',
        es: 'Imagen de Perfil',
        desc: {
            en: 'Profile image, this is ususally enabled. If disabled your church name will still be displayed.',
            es: 'Imagen de perfil, esto suele estar habilitado. Si está deshabilitado, el nombre de su iglesia aún se mostrará.'
        },
    },

    displayName: {
        en: 'Display Name',
        es: 'Nombre de Usuario'
    },

    currentStatus: {
        en: 'Current Status',
        es: 'Estado Actual'
    },
    enabled:    {
        en: 'Enabled',
        es: 'Habilitado'
    },
    disabled: {
        en: 'Disabled',
        es: 'Deshabilitado'
    },

    location: {
        en: 'Church Location Information',
        es: 'Información de Ubicación de Iglesia.',
        desc: {
            en: 'This is the location information of your church. This information is used to display your church on the map.',
            es: 'Esta es la información de ubicación de su iglesia. Esta información se utiliza para mostrar su iglesia en el mapa.'
        },
    },

 

    description: {
        en: 'Church Description',
        es: 'Descripción de la Iglesia',
        desc: {
            en: 'Allow non members to view your church description.',
            es: 'Permitir que los no miembros vean la descripción de su iglesia.'
        },
    },

    stats: {
        en: 'Stats',
        es: 'Estadísticas',
        desc: { 
            en: 'Allow non members to view date you joined Praysely and number of members.',
            es: 'Permitir que los no miembros vean la fecha en que se unió a Praysely y el número de miembros.'
        },
    },

    people: {
        en: 'People',
        es: 'Personas',
        desc: {
            en: 'Display cards of church leaders as displayed in the about section of your profile.',
            es: 'Muestre líderes de la iglesia como se muestra en la sección Acerca de su perfil.'
        },
    },

    bulletin: {
        en: 'Bulletin',
        es: 'Boletín',
        desc: {
            en: 'Allow non members to view your church bulletin.',
            es: 'Permitir que los no miembros vean el boletín de su iglesia.'
        },
    },

    updateImage: {
        en: 'Update Profile Image',
        es: 'Actualizar Imagen de Perfil'
    },

    uploadImage: {
        en: 'Upload Image',
        es: 'Subir Imagen'

    },

    cancel: {
        en: 'Cancel',
        es: 'Cancelar'
    },

    save: {
        en: 'Save',
        es: 'Guardar'
    },

    cropImage: {
        en: 'Crop and/or zoom Image',
        es: 'Recortar y/o acercar la imagen'
    },

    aspectRatio: {
        en: 'Aspect Ratio is 1:1',
        es: 'Relación de Aspecto es 1:1'
    },

    }