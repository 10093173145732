import { useEffect, useState } from 'react'
import { toast } from 'react-toastify' 
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import EventFeed from './EventFeed'
import CreateEventModal from './CreateEventModal'
import { setViewEventModal, hideEventModal } from '../../features/events/eventSlice' 
import CreateButton from 'components/buttons/CreateButton'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import Calendar from './calendar/Calendar'
import { Modal } from '@mui/material'
import { ComponentPageWrapper } from 'pages/pages.styled'
import { MobilePageTitle } from 'components/main.styled'
import { language } from 'app/language'
import { Toaster } from 'react-hot-toast';

export default function Events() {
 
    const dispatch = useDispatch() 
    const { user, admin, moderator} = useSelector((state) => state.auth)
    const {  isError, message  } = useSelector((state) => state.profile)
    const { profileChurch } = useSelector((state) => state.profileChurch)  

    const [ eventView , setEventView ] = useState( localStorage.getItem('eventView') ?? 'items'  ) // ['items', 'calendar', 'past']

    useEffect(() => {
        if(isError){toast.error(message)} 
    }, [])
 
    const { viewEventModal, viewEventModalEdit, eventEditData, isEventLoading, eventImagePreview, selectedImage, eventEditIndex } = useSelector((state) => state.events)

    const handleCreateEvent = () => {
        dispatch(setViewEventModal())
    }

    const handleViewCalendar = () => {
        if(eventView === 'items'){
            setEventView('calendar')
            localStorage.setItem('eventView', 'calendar')
        }else{
            setEventView('items')
            localStorage.setItem('eventView', 'items')
        }
    }

  return (
        
        <ComponentPageWrapper>
            <Toaster />
            <MobilePageTitle>
                {language.events[user.preferredLanguage]}
            </MobilePageTitle>

            <EventsTop>
                {(user.isOrganization || admin[profileChurch._id]) && (
                    <CreateButton 
                        handler={handleCreateEvent}
                        buttonText={language.createEvent[user.preferredLanguage]}
                        />
                ) }

                <CreateButton 
                    customIcon={eventView === 'items' ? <CalendarMonthIcon/> : <CalendarViewMonthIcon/>}
                    handler={handleViewCalendar} 
                    buttonText={eventView === 'items' ? language.viewCalendar[user.preferredLanguage] : language.viewItems[user.preferredLanguage]}
                    />
                        
            </EventsTop>
            
            {
                eventView === 'items' ? (
                    <EventFeed/>
                ) : (
                    <Calendar />
                )
            }
            
            <Modal
                open={viewEventModalEdit}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClose={() => dispatch(hideEventModal())}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div
                    style={{ 
                        width: '100%',
                        height: '100%',
                        maxHeight: '80vh', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',  
                    }}  
                    >
                <CreateEventModal
                    isEditing={true}
                    data={eventEditData}
                    index={eventEditIndex}
                    />
                </div>
            </Modal>


            <Modal  
                open={viewEventModal}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClose={() => dispatch(hideEventModal())}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div
                    style={{ 
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', 
                        padding: '10px'
                    }}  
                    >
                       <CreateEventModal /> 
                    </div>
                    
            </Modal> 
        </ComponentPageWrapper> 
    )
}


const EventsTop = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; 

    @media (max-width: 768px) { 
        width: 100%;
        margin-bottom: 0px;
        padding: 10px;
        /* margin-top: 10px; */
        align-items: center;
        justify-content: flex-start;
    }
` 

