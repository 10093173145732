import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import { useDispatch, useSelector } from 'react-redux';
import { createCampaignGivingIntentMembers, resetCampaignPayment, createCampaignGivingIntentPublic, resetCampaignIntent, setFormData } from 'features/givingCampaigns/givingCampaignSlice'; 
import {Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'; 
import { pkStripe } from 'features/constants';
import { RiSecurePaymentFill } from "react-icons/ri";
import ConfirmGivingPayment from './ConfirmGivingPayment'
import { useParams } from 'react-router-dom'
import { DH1, DonateContainer } from '../giving.styled';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { InputIconWrap } from '../giving.styled'; 
import { PiArrowBendDownLeft } from "react-icons/pi";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SpinnerSmall from 'components/SpinnerSmall'; 
import { TbHeartCheck } from "react-icons/tb";


export default function Donate() {

    const dispatch = useDispatch()  
    const params = useParams()

    const { user } = useSelector(state => state.auth) 
    const { 
        church,
        amount,
        clientSecret, 
        singleCampaignData, 
        createPaymentIntentSuccess,
        createPaymentIntentLoading,
        updateCampaignTotalsSuccess
    } = useSelector((state) => state.givingCampaign) 

    const [donationAmount, setDonationAmount] = useState(50)
    const [useCustomAmount, setUseCustomAmount] = useState(false)
    const [anonymous, setAnonymous] = useState(false)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')

    const [errorField, setErrorField] = useState({
        amount: false,
        email: false,
        name: false
    })

    const handleDonationAmount = (e, value) => { 
        if(errorField.amount){
            setErrorField({
                ...errorField,
                amount: false
            })
        }    
        setDonationAmount(value)
        dispatch(setFormData({
            key: 'amount',
            value: value
        }))
    }

    const handleConfirmAmount = () => {    
        if(!user){
            if(!amount || (!name && !anonymous) || (!email && !user)){ 
                setErrorField({
                    ...errorField,
                    amount: !amount ? true : false,
                    name: (!name && !anonymous) ? true : false,
                    email: (!email && !user) ? true : false
                }) 
                return
            }

            dispatch(createCampaignGivingIntentPublic({
                amount: amount,
                description: singleCampaignData.title,
                campaignId: params.campaignId,
                email: email,
                name: name,
                anonymous: anonymous
            }))  
        }else{ 
            if(amount < 1){
                setErrorField({
                    ...errorField,
                    amount: amount < 1 ? true : false
                })
                return
            } 
            dispatch(createCampaignGivingIntentMembers({ 
                amount: amount,
                description: singleCampaignData.title,
                campaignId: params.campaignId,
                anonymous: anonymous
            }))
        }  
    } 
 
    const stripePromise = useMemo(
        () => loadStripe( pkStripe, { stripeAccount: church.stripeId, } ), 
        [ church.stripeId ]
    )

    const handleRestartDonation = () => {
        dispatch(setFormData({  
            key: amount,
            value: 0
        }))
        setDonationAmount(50)
        setUseCustomAmount(false)
        setAnonymous(false)
        setEmail('')
        setName('')
        setErrorField({
            amount: false,
            email: false,
            name: false
        })
        dispatch(resetCampaignIntent())
    }

    const reset2 = () => {
        handleRestartDonation()
        dispatch(resetCampaignPayment())
    }

    useEffect(() => {
        //  on unmount reset payment intent data
        return () => {
            dispatch(resetCampaignIntent())
        }
    } , [])

    return (
        <DonateContainer maxHeight={'800px'} > 
            {!updateCampaignTotalsSuccess && <DH1> Donate </DH1> }

            { 
                !createPaymentIntentSuccess && !updateCampaignTotalsSuccess ?
                (
                    <PrePaymentIntentContainer> 
                        {
                            user && 
                            (
                                <div>
                                    {/* 
                                        here if a logged in user is present we can 
                                        display saved payment methods
                                    */}
                                </div>
                            )   
                        }
   {
                            !user ? 
                            (
                                <div style={{ width: '100%'  }} > 
                                    {
                                        !anonymous &&
                                        (
                                            <InputWrap> 
                                                <InputIconWrap2 isError={errorField.name && !anonymous} >
                                                    <input 
                                                        type="text" 
                                                        placeholder="Enter Your Name" 
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        />
                                                </InputIconWrap2>
                                            </InputWrap>
                                        )
                                    }

                                    <InputWrap> 
                                        <InputIconWrap2 isError={errorField.email && !user} >
                                            <input 
                                                type="email" 
                                                placeholder="Enter Your Email" 
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                />
                                        </InputIconWrap2>
                                    </InputWrap>
                                    <ABtnW>
                                        <AnCnt style={{  visibility: anonymous ? 'visible' : 'hidden' }} > 
                                            <NoAccountsIcon /> Anonymous 
                                        </AnCnt>
                                        
                                        <ABtn onClick={() => setAnonymous(!anonymous)} > 
                                            {
                                                anonymous ?
                                                (
                                                    'Display Name'
                                                )
                                                :
                                                (
                                                    'Anonymous'
                                                )
                                            }
                                        </ABtn>                            
                                    </ABtnW> 
                                </div>
                            )
                            :
                            (
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
                                    <UserInfo>
                                        {
                                            anonymous ? 
                                            (
                                                <AnCnt> <NoAccountsIcon /> Anonymous </AnCnt> 
                                            )
                                            :
                                            (
                                                <>
                                                <img    
                                                    src={ `${imgUrl}/profile/${user.imagePath}`}
                                                    alt="profile"
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                                                    />
                                                {user.fullName}
                                                </>
                                            )
                                        } 
                                    </UserInfo> 
                                    <ABtn onClick={() => setAnonymous(!anonymous)} >
                                        {
                                            anonymous ?
                                            (
                                                'Display Name'
                                            )
                                            :
                                            (
                                                'Anonymous'
                                            )
                                        }
                                    </ABtn>  
                                </div> 
                            )
                        }                            
                        <AmountInputContainer>
                        {   
                            useCustomAmount ?
                            ( 
                                <InputWrap>
                                    <label>Amount</label>
                                    <InputIconWrap>
                                        <input 
                                            type="number" 
                                            placeholder="Enter amount" 
                                            value={amount}
                                            onChange={(e) => dispatch(setFormData({
                                                key: 'amount',
                                                value: e.target.value
                                            }))}
                                            />
                                    </InputIconWrap>
                                </InputWrap> 
                            )
                            :
                            (
                                <div> 
                                    <Box sx={{ width: '100%' }}> 
                                        <Slider 
                                            min={0}
                                            max={1000}
                                            step={50}
                                            defaultValue={50} 
                                            aria-label="Default" 
                                            valueLabelDisplay="auto" 
                                            value={donationAmount}
                                            onChange={handleDonationAmount}
                                            />
                                    </Box>
                                    <YAD>
                                        You are donating: ${amount.toLocaleString()} 
                                        <CA  onClick={() => setUseCustomAmount(!useCustomAmount)}  >
                                            Enter custom amount
                                        </CA>
                                    </YAD> 
                                </div>
                            )  
                        } 
                        {
                            // errorField.amount &&
                            true && 
                            (
                                <ErrorField> <PiArrowBendDownLeft size={35} color={'red'}/>  </ErrorField>
                            )
                        } 
                        </AmountInputContainer> 
                       
                        <ConfirmButton
                            disabled={createPaymentIntentLoading}
                            onClick={handleConfirmAmount}
                            >
                            {
                                createPaymentIntentLoading ?
                                (
                                    <SpinnerSmall />
                                )
                                :
                                (
                                    <>
                                        <RiSecurePaymentFill />
                                        <span>Continue to Payment</span>
                                    </>
                                )
                            } 
                        </ConfirmButton>  
                    </PrePaymentIntentContainer>
                )
                : !updateCampaignTotalsSuccess  &&
                (
                    <YAD>
                        <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }} >
                            <span>
                                You are donating: ${amount.toLocaleString()} 
                            </span>
                            <span style={{ fontSize: '12px', color: '#ccc' }} >
                                <i style={{marginRight: '5px'}}>as</i> 
                                { 
                                    anonymous ? 
                                    (
                                        'Anonymous'
                                    )
                                    : user ?
                                    (
                                        user.fullName
                                    )
                                    :
                                    (
                                        name
                                    )
                                }
                            </span>
                        </div>
                        
                        <CA  onClick={handleRestartDonation}>
                            Restart Donation
                        </CA>
                    </YAD>                 
                )
            } 

            { 
                (stripePromise && clientSecret) &&
                (
                    <Elements stripe={stripePromise} options={{clientSecret}} > 
                        <ConfirmGivingPayment />
                    </Elements>
                )
            } 

            {
                updateCampaignTotalsSuccess &&
                (
                    <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        width: '100%',
                        height: '100%', 
                        marginTop: '-30px'
                    }} >
                        <h3 style={{ color: '#ccc', marginTop: '20px' }} > 
                            Your contribution has been sent!
                         </h3>
                        <TbHeartCheck style={{ fontSize: '70px', color: '#ccc' }} />
                        <ConfirmButton
                            onClick={reset2}
                            >
                            Restart Donation
                        </ConfirmButton>
                    </div>
                )
            }

        </DonateContainer>
    )
}

const PrePaymentIntentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const AnCnt = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    color: var(--lightThemeFontColor);

`

const UserInfo = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    color: var(--lightThemeFontColor);

    svg{
        font-size: 40px;
        margin-right: 10px;
    }

`

const AmountInputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-top: 30px;
`

const ErrorField = styled.div`
    position: absolute;
    top: -5px;
    right: -60px;
    z-index: 999;
`

const ABtnW = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`
const ABtn = styled.div`
    width: 150px;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    background-color: #ccc;
    padding: 10px;
    border-radius: 5px; 
    color: white;
    cursor: pointer;
    &:hover{
        color: darkgray;
    }

    svg{
        font-size: 15px;
        margin-right: 10px;
    
    }

`

const InputIconWrap2 = styled.div`

    width: 100%;
    transition: all 0.6s ease-in-out;
    border: ${props => props.isError ? '2px solid red' : '2px solid transparent'};
    border-radius: 7px;
    background-color: aliceblue;

    input{
        width: 100%;
        padding: 15px;
        background-color: aliceblue;
        border: 2px solid aliceblue;
        border-radius: 5px;
        outline: none;
    }

`

const ConfirmButton = styled.div`
    position: absolute;
    bottom: 20px;
    width: fit-content;
    padding: 10px 40px;
    max-width: 450px;
    right: 20px;
    display: flex;  
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${props => props.disabled ? 'white' : 'var(--lightThemeFontColor)'};
    cursor: pointer; 
    border-radius: 5px;
    // background-color: #e1e1e1; 
    background-color: ${props => props.disabled ? 'var(--themeColor)' : '#e1e1e1'};
    margin-top: 40px;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    
    svg{
        font-size: 20px;
        margin-right: 10px;
    }

    &:hover{
        color: white;
        background-color: var(--themeColor);
        
    } 

`

const InputWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    label{
        font-size: 12px;
    }
    position: relative;

`

const CA = styled.div` 
    margin-left: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    text-decoration: underline;
    cursor: pointer; 
    &:hover{
        color: darkgray;
    }

`

const YAD = styled.div` 
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--lightThemeFontColor);
`