import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl


const API_URL = '/api/requests/getRequestsSent'
const API_URL2 = '/api/requests/getRequestsReceived'
const API_URL3 = '/api/requests/acceptRequest'
const API_URL4 = '/api/requests/deleteRequest'
const API_URL5 = '/api/requests/checkRequests'

//for sending the token
//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//for users who have mad a join request --> ** not for churches **
const getRequestsSent = async (token) => {
  const response = await axios.get(URL+API_URL, config(token))
  return response.data
}

//for churches to see requests received --> ** not for users **
const getRequestsReceived = async (_, token) => {
  const response = await axios.post(URL+API_URL2, _ , config(token))
  return response.data
}

//for churches to accept requests received --> ** not for users **
const acceptRequest = async (userId, token) => {
  const response = await axios.post(URL+API_URL3, userId, config(token))
  return response.data
}

const deleteRequest = async (userId, token) => {
  const response = await axios.post(URL+API_URL4, userId, config(token))
  return response.data
}

const checkRequests = async (data, token) => {
  const response = await axios.post(URL+API_URL5, data, config(token))
  return response.data
}

const requestService = {
  getRequestsSent, getRequestsReceived, acceptRequest, deleteRequest, checkRequests
}
export default requestService