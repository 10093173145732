import styled from "styled-components"; 

export const AuthContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    font-family: "M PLUS Rounded 1c", serif;
    font-weight: 400;
    font-style: normal; 

    @media ( max-width: 768px){
        flex-direction: column;
    }

`
export const ImageBackground = styled.img`
    width: 100%;
`
export const ICTITLE = styled.div`
    font-size: 18px;
    @media ( max-width: 768px){
        background-size: 100%; 
        font-size: 14px; 
        margin-bottom: 10px;
    }
`



export const InfoContainer = styled.div`

    width: 80%;
    height: fit-content;
    opacity: .4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    background-color: black;
    color: white;
    font-size: 26px;
    padding: 40px;
    line-height: 1.9;
    border-radius: 22px;

    @media ( max-width: 768px){
        background-size: 100%;
        width: 90%;
        height: 80%;
        font-size: 24px;
        padding: 20px;
        line-height: 1.2; 
    } 
`

export const AuthSideInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;  
    background-color: #c8e8f7;
    background-image: url('https://api.praysely.com/prys/lp-church.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    @media ( max-width: 768px){
        background-size: 100%;
        width: 100%;
        height: 400px;
    }

`

export const AuthSideForm = styled.div`
    width: 30%;
    height: 100%; 
    background-color: aliceblue;

    @media ( max-width: 768px){
        width: 100%;
    }

`

export const FormContainer = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 35px;
    align-items: flex-start;
    justify-content: space-between; 

    overflow-y: ${props => props.formType === 'register' ? ('scroll') : ('hidden')};

`

export const LoginHeader = styled.div` 
    
    display: flex;
    flex-direction: column;
    width: 100%;  

`

export const FormMain = styled.div`

    width: 100%; 

`

export const FormMainHeader = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;

    h1{
        font-size: 20px;  
    }

    p{
        font-size: 14px;
        color: gray;
    }

`

export const InputItemWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;

`

export const InputLabel = styled.label`


    font-size: 14px;
    color: gray;
    margin-bottom: 4px;

`

export const InputFieldItem = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    border: ${props => props.isError ? ('1px solid red') : ('1px solid #ccc')};
    border-radius: 5px; 
    input{
        width: 100%;
        padding: 18px; 
        border-radius: 5px; 
        outline: none;
        border: none;
    }
 

`
export const AuthButton = styled.button`
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    background-color: var(--themeColor);
    color: white;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    margin-top: 20px;

    &:hover{
        background-color: #0056b3;
    }

`

export const ForgotPasswordButton = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 10px;
    font-size: 14px;
    color: gray;
    cursor: pointer;

    span{
        color: gray;
    }   
`

export const DHA = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: gray;
    margin-top: 20px;

    p{
        color: gray;
        font-weight: 500;
        cursor: pointer;
        margin-left: 10px;
        text-decoration: underline;
    }


`

export const LoginFooter = styled.div`
    font-size: 14px;
    color: gray;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

`
export const TabSelectWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
`

export const UserTypeTabSelect = styled.div`    
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
`

export const TabItem = styled.div`
    position: relative;
    width: 50%;
    aspect-ratio: 1/1; 
    color: ${props => props.selected ? ('var(--themeColor)') : ('#ccc')};
    font-size: 13px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: ${props => props.selected ? ('2px solid var(--themeColor)') : ('2px solid white')};
    border-radius: 25px;

    &:hover{ 
        border: ${props => props.selected ? ('2px solid var(--themeColor)') : ('2px solid #ccc')};
    } 
`

export const CheckWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    position: absolute;
    right: -10px;
    bottom: -10px;
    background-color:var(--themeColor);

    svg{
        color: white;
    }
`