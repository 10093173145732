import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getTransactions } from 'features/billing/billingSlice'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TransactionItem from './TransactionItem'
import Loader from "loaders/Loader";
import { ViewHeading } from '../settings.styled';

export default function Transactions() { 
  const dispatch = useDispatch() 
  const {  isGetTransactionsLoading, transactions  } = useSelector((state) => state.billing)
  
  useEffect(() => {
    if(transactions.length === 0) dispatch(getTransactions()) 
  }, [])


  return (
    <TransactionsContainer>
             
      <ViewHeading> <ReceiptLongIcon/><span style={{marginLeft: '10px'}}>Transactions History</span>   </ViewHeading>
        
    { isGetTransactionsLoading ? 
      (
          <SpinnerWrap> <Loader  /> </SpinnerWrap>
      )
      : transactions && transactions.length > 0 ?
      (
        <TransactionsTableContainer>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Card</th>
              <th>Action</th>
              <th>Description</th>
              <th>Receipt</th>
            </tr>

          {transactions.map((ele) => 
            (
              <TransactionItem key={ele.id} data={ele} />
            ) 
          )}          
          </tbody>
        </TransactionsTableContainer>

      )
      :
      (
        <span>no transactions</span>
      )
    }
    </TransactionsContainer>
  )
}

const SpinnerWrap = styled.div`margin-top: 10px;`

const TransactionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
 
const TransactionsTableContainer = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  cursor: context-menu;
  margin-left: 20px;
  color: #585858;
  th {
    
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }


  td {
    color: #585858;
    position: relative;
    text-align: center;
    font-size: 12px;
    border: 1px solid #dddddd;
    padding: 2px;
  }

`