import { useEffect } from 'react'
import '../css/pages/settings.css'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux' 
import { getMyData } from '../features/profile/profileSlice'
import SettingsComponent from '../components/settings/SettingsComponent'
import UpdateComponent from '../components/settings/UpdateComponent'
import ReportIssueModal from '../components/modals/ReportIssue/ReportIssueModal'
import styled from 'styled-components'
import UserExtras from '../components/userProfile/UserExtras'
import ChurchExtras from '../components/userProfile/ChurchExtras'
import Loader from "loaders/Loader";
import ConfirmModal from 'components/modals/ConfirmModal' 
import { toast } from 'react-toastify'
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice'
import { PageWrapper, PageContainerMain } from './pages.styled'
import Modal from '@mui/material/Modal';
import { addAc, setViewPrefferedLanguageModal } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import LanguageModal from 'components/settings/LanguageModal'


function Settings() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, isError, message, viewPrefferedLanguageModal } = useSelector((state) => state.auth) 
  const { isSuccess, isLoading} = useSelector((state) => state.profile)
  const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)
  const { viewReportIssueModal } = useSelector((state) => state.settings) 
  const { isMemberActionSuccess, isMemberActionError, memberActionMessage } = useSelector((state) => state.memberships2)

  useEffect(() => { 
    if (isMemberActionSuccess) {
      toast(memberActionMessage)
      dispatch(setConfirmModalData({
          text: '',
          handler: null,
          confirmBtnBackgroundHover: null,
          extraInfo: null
      }))
      dispatch(setViewConfirmModal(false))
    }else if(isMemberActionError){
      toast(memberActionMessage)
      dispatch(setConfirmModalData({
        text: '',
        handler: null,
        confirmBtnBackgroundHover: null,
        extraInfo: null
    }))
    dispatch(setViewConfirmModal(false))
    }
  }, [isMemberActionSuccess, dispatch, isMemberActionError, memberActionMessage])

  useEffect(() => {
    if (isError) { console.log(message)}
    if (!user) { navigate('/login') }
    dispatch(getMyData())
    // return () => { dispatch(resetProfile()) } //changed  
  }, [user, dispatch, navigate, isError, message])

  useEffect(() => {

    const dis = () => dispatch(addAc({
      type: 'settings', 
    }))

    addAcFunc('settings', dis)

  }, [])


  return (
    <PageWrapper>
      <PageContainerMain>
        <SideBar>
              { user.isOrganization || user.isChurch ? 
                ( <ChurchExtras/> )
                :
                ( <UserExtras/> )        
              }
          </SideBar>
          <SettingsElementsContainer>
            <SettingsComponent  />
            <SettingToUpdateWrap>
                { isSuccess?
                  ( <UpdateComponent  /> )
                  : isLoading ?
                  ( 
                    <div style={{marginTop: '50px'}}> 
                      <Loader /> 
                    </div> 
                  )
                  :
                  ( null )
                  }
            </SettingToUpdateWrap>
              {
                viewReportIssueModal && ( <ReportIssueModal  /> )
              }
          </SettingsElementsContainer> 


          <Modal
            open={viewPrefferedLanguageModal}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClose={() => dispatch(setViewPrefferedLanguageModal(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center',  
                width: '100vw', 
                height: '100vh',  
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              >   
                <LanguageModal />
            </div>
          </Modal>

        </PageContainerMain> 
    </PageWrapper>
  )
}
export default Settings

const SideBar = styled.div`
    word-wrap: normal;
    display: flex;
    flex-direction: column;
    width: fit-content;
`

const SettingToUpdateWrap = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`

const SettingsElementsContainer = styled.div`
    margin-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
`