import React from 'react'
import { TabSelectWrap, UserTypeTabSelect, TabItem, CheckWrapper } from '../../pages/auth/auth.styled'

import { FiUser } from "react-icons/fi";
import { PiChurchLight } from "react-icons/pi";
import { FaCheck } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { setUserType } from '../../features/auth/authSlice'



export default function SelectUserType() {

    const dispatch = useDispatch()
    const { userType } = useSelector((state) => state.auth)

    const handleUserType = (value) => { 
        dispatch(setUserType(value))
    }



    return (
        <TabSelectWrap>
            <UserTypeTabSelect>
                <TabItem
                    side={'left'}
                    onClick={() => handleUserType('user')}
                    selected={userType === 'user' ? (true):(false)}
                    >
                    <FiUser size={24}  />
                    <span>User</span>
                    {
                        userType === 'user' && (
                            <CheckWrapper>
                                <FaCheck  />
                            </CheckWrapper>
                        )
                    }
                </TabItem>
                <TabItem
                    side={'right'}
                    onClick={() => handleUserType('church')}
                    selected={userType === 'church' ? (true):(false)}
                    >
                    <PiChurchLight size={24} />
                    <span>Church</span>

                    {
                        userType === 'church' && (
                            <CheckWrapper>
                                <FaCheck  />
                            </CheckWrapper>
                        )
                    }
                </TabItem>
            </UserTypeTabSelect>
        </TabSelectWrap>
    )
}
