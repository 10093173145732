import styled from "styled-components";


export const SettingsBody = styled.div`

    width: 100%;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }

`

export const ViewHeading = styled.div` 
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    color: var(--lightThemeFontColor);
    
`


export const SettingsInputContainer = styled.div`
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-shadow: 0px 0px 10px #ccc;
    transition: all 0.4s ease-in-out;

    /* if it is focused make changes */
    &:focus-within{

        box-shadow: 0px 0px 15px #aaaaaa;
    }

    input{
        height: 100%;
        border: none;
        outline: none;
        background-color: white;
        margin-left: 10px;
        width: calc(100% - 100px); 
    }

`

export const SettingsSubmitButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--themeColor);
    border: none;
    outline: none;
    border-radius: 15px;
    width: 75px;
    height: 100%;
    color: white;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 60px;
        font-size: 11px;
    }
`



export const SettingsMainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    color: var(--dFontColor);
    font-weight: 300;

    p{
        margin-left: 40px;
        text-align: left;
        font-size: 13px;
        color: var(--dFontColor);
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
    }
    @media (max-width: 768px) {
        p{
            margin-left: 5px;
        }
    }
`

export const CenterSettingsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const SettingsViewContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Roboto', sans-serif;
    color: var(--dFontColor);
    font-weight: 300;

`
export const TopHeaderSettings = styled.div`    
    border-bottom: 1px solid var(--borderColor);
    padding: 20px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    color: var(--lightThemeFontColor);
    font-weight: 300;
    width: 100%;
`
export const SettingsMainBtn = styled.div` 
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--themeColor);
    border-radius: 5px;
    padding: 10px;
    width: 100px;
    height: 40px;
    color: white;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    &:hover {
        background-color: var(--themeColorDark);
    }
`
export const SettingsMainBtnCancel = styled.div` 
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: none;
    border-radius: 5px;
    padding: 10px;
    width: 100px;
    height: 40px;
    color: grey;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

`

export const SettingsLi = styled.div`
    display: flex;
    text-align: left;
    padding: 8px 30px;
    margin-bottom: 10px;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;
    color: var(--lightThemeFontColor);
    transition: all 0.4s;
    border-radius: 5px;

    &:hover{
        background-color: rgb(221, 221, 221);
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.4s;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 4px 10px;
        font-size: x-small;
        transition: all 0.4s;
        padding: 10px;
        
    }
`

export const SettingsItemWrapper = styled.div`


`

export const UploadImageButton = styled.label`
    font-size: 12px;
    padding: 10px 15px;
    background-color: var(--themeColor);
    color: white;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${props => props.width ? props.width : "200px"};
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    
    svg{
        margin-right: 5px;
    }
    &:hover{
        background-color: var(--themeColorDark);
        width: ${props => props.width ? props.width : "210px"};
    }

`

export const StyledHelpIcon = styled.div`
    color: var(--LightThemeFontColor);
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 10px;
    width: fit-content;
    padding: 10px; 

    &:hover{
        background-color: ${props => props.hoverColor ? props.hoverColor : "white"};
    }
 
`

export const StartTrialContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  

`

export const STHeader = styled.h1`
    font-size: 30px;
    width: 100%;
    margin-top: 20px;
    color: var(--themeColor);
    font-family: 'Roboto', sans-serif;
    font-weight: 600; 
    padding: 20px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
`
export const LineHor = styled.div` 
    height: 1px;
    background-color: var(--borderColor);
    margin-left: 20px;
    width: 40%;
    align-self: start;

`

export const STInfo = styled.div`
    font-size: 19px;
    width: 100%;
    color: gray;
    font-family: 'Roboto', sans-serif;
    font-weight: 300; 
    padding: 20px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

`
export const StartButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px; 
`

export const StartButton = styled.button`
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: lightgreen;
    border-radius: 25px;
    border: none;
    outline: none;
    padding: 10px;
    border: 3px solid lightgreen;
    width: 200px;
    height: 45px;
    color: gray;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    &:hover {
        background-color: aliceblue;
        border: 3px solid gray;
    }

`

export const EXIn = styled.div`

    width: 100%;
    color: gray;
    padding: 20px;
    padding-left: 0px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-align: left;

`   

export const PremiumContainer = styled.div`

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
    padding-left: 0px;

`

export const ItemBox = styled.div`
    width: 50%;
    position: relative;

    min-height: fit-content;
    border: ${props => props.selected ? "3px solid var(--themeColor)" : "3px solid white"};
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 10px #ccc;
    transition: all 0.2s ease-in-out;

    &:hover{
        border: ${props => props.selected ? "3px solid var(--themeColor)" : "3px solid #ccc"};
        box-shadow: 0px 0px 10px transparent;
    }

`

export const PremiumTitle = styled.div`
    font-size: 20px;
    font-family: 'Roboto', sans-serif; 
    color: gray;
    padding: 10px;

`

export const PremiumPrice = styled.div`

    font-size: 20px;
    font-family: 'Roboto', sans-serif; 
    color: gray; 
    padding: 10px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    b{
        font-size: 32px; 
    }

`

export const PremiumFeatures = styled.div`

    text-align: left;

    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            padding: 10px;
            font-size: 14px;
            color: gray;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
        }
    }

`


export const CheckBoxIcon = styled.div`

    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--themeColor); 

`
export const StartPlanButton = styled.button`
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: gray;
    border-radius: 12px;
    border: none;
    outline: none;
    padding: 10px;
    width: 220px;
    height: 55px;
    color: white;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;

    &:hover {
        background-color: var(--themeColor);
    }

`