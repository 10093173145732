import React, { useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getSubscription, setViewSubscribeModal } from 'features/billing/billingSlice'
import Spinner from 'components/Spinner' 
import SubItem from './SubItem' 
import SubscribeModal from './SubscribeModal' 
import StartSubscription from './StartSubscription' 
import { Modal } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'; 
import ConfirmModal from 'components/modals/ConfirmModal'
import { setViewConfirmModal } from 'features/modal/modalSlice'


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

export default function Subscriptions() {
  const SubsciptionSignUp = lazy(() => import('./SubsciptionSignUp'))
 

  const dispatch = useDispatch()
  const { 
    subscription,   
    isGetSubscriptionLoading,
    isCreateSubscriptionSuccess,
    isGetSubscriptionSuccess,
    viewSubscribeModal,
    intentSuccess,
    clientSecret, 
    cancelSubscriptionSuccess,
    cancelSubscriptionLoading,
    resumeSubscriptionLoading,
    resumeSubscriptionSuccess
  } = useSelector((state) => state.billing)

  const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)


  useEffect(() => {
    if(!isCreateSubscriptionSuccess){
      dispatch(getSubscription())
    }
  }, [isCreateSubscriptionSuccess, dispatch])


  return (
    <SubscriptionsContainer> 
    { isGetSubscriptionLoading ? 
      (
          <SpinnerWrap> <Spinner  /> </SpinnerWrap>
      ) 
      : 
      ( isGetSubscriptionSuccess &&
        (
          <>


            { subscription ? 
              ( 
                <SubItem data={subscription} />    
              
              )
              :
              (
                <StartSubscription />
              )  
            }

            <Modal
              open={viewSubscribeModal && intentSuccess && clientSecret}
              onClose={() => dispatch(setViewSubscribeModal(false))}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Elements stripe={stripePromise} options={{clientSecret}} > 
                <SubscribeModal /> 
              </Elements>
            </Modal> 
            <Modal
              open={viewConfirmModal}
              style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              onClose={() => dispatch(setViewConfirmModal(false))}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
            <ConfirmModal 
              text={confirmModalData.text} 
              handler={confirmModalData.handler} 
              confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
              extraInfo={confirmModalData.extraInfo}
              loadingState={cancelSubscriptionLoading || resumeSubscriptionLoading}
              conditionToCloseModal={cancelSubscriptionSuccess || resumeSubscriptionSuccess}
              /> 
            </Modal>
          </>    
        )
      )
    }
    </SubscriptionsContainer>
  )
}

const SpinnerWrap = styled.div`margin-top: 10px;`

const SubscriptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
 