import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice' 
import postReducer from '../features/posts/postSlice'
import singlePostReducer from '../features/posts/singlePostSlice'
import singlePostCommentReducer from '../features/comments/singlePostCommentSlice'
import organizationReducer from '../features/organization/organizationSlice'
import profileReducer from '../features/profile/profileSlice'
import profileChurchReducer from '../features/profileChurch/profileChurchSlice'
import commentReducer from '../features/comments/commentSlice'
import mailReducer from '../features/mail/mailSlice'
import searchReducer from '../features/search/searchSlice'
import PassResetReducer from '../features/passwordReset/passResetSlice'
import resetErrorReducer from '../features/error/errorSlice'
import membershipsReducer from '../features/createPost/membershipsSlice' //no longer using this one << ---
import selectPostingToReducer from '../features/createPost/selectPostingToSlice'
import postImageReducer from '../features/createPost/postImageSlice'
import postImagePreviewReducer from '../features/createPost/postImagePreviewSlice'
import selectPostTypeReducer from '../features/createPost/selectPostTypeSlice'
import allowCommentsReducer from '../features/createPost/allowCommentsSlice'
import notifReducer from '../features/notifs/notifSlice'
import imageReducer from '../features/settings/imageSlice'
import requestReducer from '../features/requests/requestSlice'
import membersReducer from '../features/memberships/membershipSlice'
import messageReducer  from '../features/messenger/messengerSlice'
import conversationReducer from '../features/messenger/conversationSlice'
import reportIssueReducer from '../features/reportIssue/reportIssueSlice'
import modalReducer from '../features/modal/modalSlice'
import directoryModalReducer from '../features/modal/directoryModalSlice'
import directorySlice from '../features/directory/directorySlice'
import eventSlice from '../features/events/eventSlice'
import cvSlice from 'features/churchVerification/cvSlice'
import feedbackSlice from 'features/feedback/feedbackSlice'
import pollSlice from 'features/polls/pollSlice'
import bulletinSlice from 'features/bulletin/bulletinSlice'
import announcementSlice from 'features/anouncements/announcementSlice'
import settingSlice from 'features/settings/settingSlice'
import billingSlice from 'features/billing/billingSlice'
import mapSlice from 'features/maps/mapSlice'
import emailSlice from 'features/email/emailSlice'
import prayerWallSlice from 'features/prayerWall/prayerWallSlice'
import marketSlice from 'features/marketplace/marketSlice'
import videoSlice from 'features/videos/videoSlice'
import fileSlice from 'features/files/fileSlice'
import audioSlice from 'features/audio/audioSlice'
import audioPlayerSlice from 'features/audio/audioPlayerSlice'
import givingSlice from 'features/giving/givingSlice'
import givingCampaignSlice from 'features/givingCampaigns/givingCampaignSlice'
 

export const store = configureStore({
  reducer: {
    
    auth: authReducer,
    notifs: notifReducer,
    profile: profileReducer,
    profileChurch: profileChurchReducer,
    organization: organizationReducer,
    posts: postReducer,
    memberships: membershipsReducer,
    singlePost: singlePostReducer,
    sPComments: singlePostCommentReducer, 
    comments: commentReducer,
    mail: mailReducer,
    searchValues: searchReducer,
    isPassResetSuccess: PassResetReducer,
    hasError: resetErrorReducer,
    postingTo: selectPostingToReducer,
    postImage: postImageReducer,
    postImagePreview: postImagePreviewReducer,
    postType: selectPostTypeReducer,
    allowComments: allowCommentsReducer,
    image: imageReducer,
    requests: requestReducer,
    memberships2: membersReducer,
    messages: messageReducer,
    conversations: conversationReducer,
    issues: reportIssueReducer,
    modal: modalReducer,
    directoryModal: directoryModalReducer,
    directory: directorySlice,
    events: eventSlice,
    churchVerification: cvSlice,
    feedback: feedbackSlice,
    poll: pollSlice,
    bulletin: bulletinSlice,
    announcement: announcementSlice,
    settings: settingSlice,
    billing: billingSlice,
    maps: mapSlice,
    email: emailSlice,
    prayerWall: prayerWallSlice,
    market: marketSlice,
    video: videoSlice,
    audio: audioSlice,
    audioPlayer: audioPlayerSlice,
    file: fileSlice,
    giving: givingSlice,
    givingCampaign: givingCampaignSlice
  },
  devTools: process.env.REACT_APP_ENV === 'production' ? false : true
})



//https://www.roc-search.com/job-search/?keyword=software
// i like the way these search results look!!!