import { useEffect, useState } from "react";
import styled from 'styled-components'; 
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createSubscription, setViewSubscribeModal, setCreateSubscriptionLoading } from 'features/billing/billingSlice' 
import { updateUser } from "features/auth/authSlice";
import { IoIosArrowRoundBack } from "react-icons/io"; 
import { RiSecurePaymentLine } from "react-icons/ri"; 
import Spinner from "components/Spinner"; 
import { PaymentElement,  useStripe,  useElements } from '@stripe/react-stripe-js'; 
import SpinnerSmall from "components/SpinnerSmall";

export default function SubscribeModal() {
 
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const { intentLoading, intentType, selectedPlan, intent, dueToday, subscriptionPrice, createSubscriptionLoading, } = useSelector((state) => state.billing) 

    const handleBack = () => { dispatch(setViewSubscribeModal({ view: false }))}

    const handleConfirmSubscription = async () => {
      if(!stripe || !elements){ return }
      dispatch(setCreateSubscriptionLoading(true))

      if(intentType === 'payment'){
        const {error} = await stripe.confirmPayment({ elements, confirmParams: { }, redirect: 'if_required' }); 
        if (error) {  
          toast.error('Error occurred')
          dispatch(setCreateSubscriptionLoading(false))
        } else {  
          dispatch(createSubscription({ 
            intent: intent.id,
            intentType: intentType,
          }))
        }
      }else if(intentType === 'setup'){

        const {error} = await stripe.confirmSetup({ elements, confirmParams: { }, redirect: 'if_required' });
        
        if (error) {  
          toast.error('Error occurred.')
          dispatch(setCreateSubscriptionLoading(false))
        } else {   
          dispatch(createSubscription({ 
            intent: intent.id,
            intentType: intentType,
          }))
        } 
      } 
    }
    
    // useEffect(() => {
    //   if(isCreateSubscriptionSuccess){ 
    //     dispatch(updateUser({
    //       type: 'isPremium',
    //       value: true
    //     })) 
    //     toast.success('Subscription activated successfully!')
    //     dispatch(setViewSubscribeModal(false)) 
    //   }
    // }, [isCreateSubscriptionSuccess, dispatch])
  
     
  return (
    <SubModalContainer>
        <SubModalWrap >
          {
            intentLoading ?
            (
              <LoadingContainer>
                <Spinner  />
              </LoadingContainer>
              
            )
            : 
            (
              < > 
                <AmountContainer>

                  <BackButton
                    onClick={handleBack}
                    >
                    <IoIosArrowRoundBack style={{ fontSize: '30px', color: '#727575' }} />
                    <span> Go Back </span>
                  </BackButton>

                  <PaymentHeader style={{  
                    marginTop: '-20px',
                    }} >
                    <img src='/praysely-logo.png' alt='praysely-logo' style={{ width: '100px' }} />
                    <span> Subscribe to Premium </span>
                  </PaymentHeader>

                  <PaymentReview>
                    <div>
                      <b> Praysely Premium </b>
                      <b>${(subscriptionPrice/100).toFixed(2).toString()}</b>                      
                    </div>

                    <div>
                      Billed {selectedPlan}
                    </div>

                    <div
                      style={{ marginTop: '30px' }}
                      >
                      <b> Total due today </b>
                      <b>${(dueToday/100).toFixed(2).toString()}</b>
                    </div>
                  </PaymentReview> 
                </AmountContainer>
                <CardFormContainer>
                  <PaymentHeader>
                    <RiSecurePaymentLine style={{ fontSize: '30px', color: '#727575' }} />
                    <span> Pay With Card </span>
                  </PaymentHeader>
                  <PaymentElement  
                    // options={{ appearance }}
                    /> 
                  <SubscribeBtn
                    disabled={createSubscriptionLoading}
                    onClick={handleConfirmSubscription}
                    > 
                    {
                      createSubscriptionLoading ? 
                      (
                        <SpinnerSmall />
                      )
                      :
                      (
                        <span> Subscribe </span>
                      )
                    } 
                  </SubscribeBtn>
                  <PaymentInfo>
                    By confirming your subscription, you allow Praysely to charge you for future payments in accordance with their terms. You can always cancel your subscription.
                  </PaymentInfo>
                  <BottomInfo>
                    <img src='/stripe-logo.svg' alt='stripe-logo' style={{  width: '110px' }} />
                    {/* Vertical bar */} 
                    <span
                      onClick={() => {
                        window.open('https://stripe.com/legal/consumer', '_blank')
                      }}
                      >Terms</span>
                    <span
                      onClick={() => {
                        window.open('https://stripe.com/privacy', '_blank')
                      }}

                      >Privacy</span> 
                  </BottomInfo>
                  
                </CardFormContainer>
              </>
            )
          } 
        </SubModalWrap>
    </SubModalContainer>
  )
}

const SubModalContainer = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
`
const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
`

const PaymentHeader = styled.div`

    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #727575;

`

const PaymentReview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    padding-top: 30px;
    color: #727575;
    border-top: 1px solid #eaeaea;
    width: 100%; 
    div{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
    span{

    }

`

const SubModalWrap = styled.div`
    background-color: white;
    width: 950px; 
    height: 700px; 
    padding-bottom: 20px;
    border-radius: 11px;
    display: flex;
    flex-direction: row;
    position: relative;
    cursor:  ${props => props.loading ? 'wait':'auto'}; 
    @media (max-width: 768px) {
        width: 95%;
        flex-direction: column;
        border-radius: 7px;
        height: fit-content;
        overflow-y: scroll;
        padding-top: 20px;
    }
    @media (max-width: 568px) {
        width: 95%;
        border-radius: 7px;
    }

`

const BackButton = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    position: absolute;
    padding: 4px 20px;
    border-radius:15px;
    top: 30px;
    left: 30px;
    color: #727575;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
      background-color: #d7d7d7;
    }

`


const AmountContainer = styled.div`
  position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: aliceblue;
    padding: 0px 40px 20px 40px;

    @media (max-width: 768px) {
        padding: 0px 20px 20px 20px;
        width: 100%;
      }

`

const CardFormContainer = styled.div`  
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media (max-width: 768px) {
        padding: 20px 20px 20px 20px;
        width: 100%;
      }
`

const SubscribeBtn = styled.button`
  pointer-events: ${props => props.disabled ? 'none':'auto'};
    margin-top: 40px;
    width: 100%;
    height: 45px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: center;
    background-color: green;
    color: white;
    border: none;
    outline: none;
    cursor: pointer; 
    transition: all 0.3s ease-in-out;

    &:hover{
        background-color: #04a804;
    }

`

const PaymentInfo = styled.div`
    font-size: 14px;
    color: gray;
    margin-top: 20px;
    line-height: 1.5;
    font-weight: 500; 
`

const BottomInfo = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 16px;
    color: #727575;
    font-weight: 500;
    cursor: pointer;

    span{
        &:hover{
            text-decoration: underline;
        }
    }

`