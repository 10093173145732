import React from 'react'
import { AuthContainer, AuthSideInfo, AuthSideForm, InfoContainer, ICTITLE } from './auth.styled' 
import LoginForm from 'components/auth/LoginForm'
import { Modal } from '@mui/material'
import { useSelector } from 'react-redux'
import ForgotPasswordModal from 'components/userProfile/ForgotPasswordModal'
import RegisterForm from 'components/auth/RegisterForm'
import EmailConfirmationModal from 'components/register/EmailRegisterModal'

export default function AuthRegister() {

      const { 
        viewEmailConfirmModal, 
        viewForgotPasswordModal
       } = useSelector((state) => state.auth)

    return (
        <AuthContainer>
            <AuthSideInfo>
                <InfoContainer>
                    <ICTITLE>Who we are</ICTITLE>
                    <span>
                        A faith-based community platform designed to 
                        connect churches and members, fostering engagement, support, 
                        and spiritual growth—anytime, anywhere.
                    </span>
                </InfoContainer>
            </AuthSideInfo>
            <AuthSideForm> 
                <RegisterForm />
            </AuthSideForm>
            <Modal
                open={viewForgotPasswordModal}  
                >
                    <ForgotPasswordModal />
            </Modal>
            <Modal
                open={viewEmailConfirmModal}
                >
                    <EmailConfirmationModal />
            </Modal>


        </AuthContainer>
    )
}


