import { useSelector, useDispatch } from 'react-redux'
import { getMyData } from '../../features/profile/profileSlice'  
import { setViewPrefferedLanguageModal } from '../../features/auth/authSlice'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import PortraitIcon from '@mui/icons-material/Portrait';
import GroupIcon from '@mui/icons-material/Group';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PasswordIcon from '@mui/icons-material/Password';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from 'react-router-dom'
import { setSettingSection, setViewReportIssueModal } from 'features/settings/settingSlice'
import Loader from "loaders/Loader";
import BadgeIcon from '@mui/icons-material/Badge'; 
import SendIcon from '@mui/icons-material/Send';
import {  SettingsItemWrapper, SettingsLi } from './settings.styled'
import { useEffect } from 'react';
import PreviewIcon from '@mui/icons-material/Preview'; 
import { FaMoneyBillTransfer } from "react-icons/fa6";
import TranslateIcon from '@mui/icons-material/Translate';
import { settingsLanguage } from './settingsLanguage'
import { TbTopologyStarRing3 } from "react-icons/tb";

function SettingsComponent(props) {

    const location = useLocation();
    const section =  new URLSearchParams(location.search).get('section') ? new URLSearchParams(location.search).get('section') : 'none'
  
    const { user, viewPrefferedLanguageModal } = useSelector((state) => state.auth)
    const { profile, isLoading } = useSelector((state) => state.profile)


    const dispatch = useDispatch()
    const navigate = useNavigate()
    if (!profile) {  dispatch(getMyData()) }
    
    const handleSettingsNav = (value) => {
        navigate(`/settings?section=${value}`)
        dispatch(setSettingSection(value))
    }

    const handleLanguageModal = () => { 
        dispatch(setViewPrefferedLanguageModal(true))
    }

    const handleSettingsReport = () => {
        dispatch(setViewReportIssueModal(true))
    }

    useEffect(() => {
        console.log('section: ' + section)
    }, [section])

    return (
        <SettingsListContainer>
            {isLoading ? 
                (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Loader />   
                        
                    </div>
                ) 
                : 
                (
                    <SettingsWrap>
                        <SettingsTitle>
                            {settingsLanguage.settings[user.preferredLanguage]}
                        </SettingsTitle>
                        <SettingsItemWrapper>

                            {
                                user.isOrganization && (
                                    <>
                                        <SettingsLiInvite 
                                            onClick={() => handleSettingsNav('invite')}
                                            >
                                            <SendIcon />
                                            <SettingsItemTitle>
                                                {
                                                    settingsLanguage.inviteMembers[user.preferredLanguage]
                                                }
                                            </SettingsItemTitle> 
                                        </SettingsLiInvite> 
                                        <SettingsLi onClick={() => handleSettingsNav('billing')}>
                                            <TbTopologyStarRing3 size={23} />
                                            <SettingsItemTitle>
                                                {
                                                    settingsLanguage.premium[user.preferredLanguage]
                                                }
                                            </SettingsItemTitle> 
                                        </SettingsLi> 
                                        <SettingsLi onClick={() => handleSettingsNav('giving')} >
                                            <FaMoneyBillTransfer fontSize={'26px'} /> 
                                            <SettingsItemTitle >
                                                {settingsLanguage.memberGiving[user.preferredLanguage]}
                                            </SettingsItemTitle>  
                                        </SettingsLi>
                                    </>
                                    
                                )
                            }

                            <SettingsLi onClick={() => handleSettingsNav('memberships')} >
                                <GroupIcon />
                                {user.isOrganization ? (
                                    <SettingsItemTitle >
                                        {
                                            settingsLanguage.members[user.preferredLanguage]
                                        }
                                    </SettingsItemTitle>  
                                ):
                                (
                                    <SettingsItemTitle >
                                        {
                                            settingsLanguage.memberships[user.preferredLanguage]
                                        }
                                    </SettingsItemTitle>  
                                )}
                            </SettingsLi>
                            {user.isOrganization ? (
                                <>
                                    <SettingsLi onClick={() => handleSettingsNav('requestsReceived')} >
                                        <GroupAddIcon/>
                                        <SettingsItemTitle>
                                            {settingsLanguage.requests[user.preferredLanguage]}
                                        </SettingsItemTitle>
                                    </SettingsLi>
                                    {/* <SettingsLi onClick={() => handleSettingsNav('profile')}>
                                        <PreviewIcon />
                                        <SettingsItemTitle><nobr>
                                            {settingsLanguage.viewSettings[user.preferredLanguage]}
                                            </nobr> </SettingsItemTitle>   
                                    </SettingsLi> */}
                                </>
                            )
                            :
                            (
                                <SettingsLi onClick={() => handleSettingsNav('requests')} >
                                    <GroupAddIcon />
                                    <SettingsItemTitle>
                                        {settingsLanguage.requests[user.preferredLanguage]}
                                    </SettingsItemTitle>   
                                </SettingsLi>
                            )
                            }

                            <SettingsLi onClick={() => handleSettingsNav('addImage')}>
                                <PortraitIcon />
                                <SettingsItemTitle>
                                    <nobr>
                                        {settingsLanguage.profileImage[user.preferredLanguage]}
                                    </nobr> 
                                </SettingsItemTitle>   
                            </SettingsLi>

                            {!user.isOrganization &&
                                // (
                                //     <SettingsLi onClick={() => handleSettingsNav('myChurchDirectory')}>
                                //         <FolderSharedIcon />
                                //         <SettingsItemTitle><nobr>
                                //                 {
                                //                     settingsLanguage.churchDirectory[user.preferredLanguage]
                                //                 }
                                //             </nobr></SettingsItemTitle> 
                                //     </SettingsLi>
                                // )
                                // :
                                (
                                    <SettingsLi onClick={() => handleSettingsNav('directory')}>
                                        <FolderSharedIcon />
                                        <SettingsItemTitle>
                                            <nobr>
                                                {
                                                    settingsLanguage.myDirectoryItem[user.preferredLanguage]
                                                }
                                            </nobr></SettingsItemTitle> 
                                    </SettingsLi>
                                )
                            }

                            <SettingsLi onClick={() => handleSettingsNav('fullName')}>
                                <DriveFileRenameOutlineIcon/>
                                <SettingsItemTitle >
                                    <nobr>
                                        {
                                            settingsLanguage.displayName[user.preferredLanguage]
                                        }
                                    </nobr>
                                </SettingsItemTitle> 
                            </SettingsLi>

                            {
                                user.isOrganization && (
                                    <SettingsLi onClick={() => handleSettingsNav('username')}>
                                        <BadgeIcon/>
                                        <SettingsItemTitle>
                                            <nobr>
                                                {
                                                    settingsLanguage.username[user.preferredLanguage]
                                                }
                                            </nobr>
                                        </SettingsItemTitle>  
                                    </SettingsLi>
                                )
                            }

                            <SettingsLi onClick={() => handleSettingsNav('email')} >
                                <MailOutlineIcon />
                                <SettingsItemTitle>
                                    {
                                        settingsLanguage.email[user.preferredLanguage]
                                    }
                                </SettingsItemTitle> 
                            </SettingsLi>
                            <SettingsLi onClick={() => handleSettingsNav('password')}>
                                <PasswordIcon />
                                <SettingsItemTitle>
                                    {
                                        settingsLanguage.password[user.preferredLanguage]
                                    }
                                </SettingsItemTitle> 
                            </SettingsLi>

                        </SettingsItemWrapper>
                    </SettingsWrap>
                )}
                           
                <SettingsLi  onClick={handleLanguageModal}>
                    <TranslateIcon/>
                    <SettingsItemTitle>
                        {
                            settingsLanguage.language[user.preferredLanguage]
                        }
                    </SettingsItemTitle>  
                </SettingsLi>

                <SettingsLi  onClick={handleSettingsReport}>
                    <FlagCircleIcon/>
                    <SettingsItemTitle>
                        {
                            settingsLanguage.report[user.preferredLanguage]
                        }
                    </SettingsItemTitle>  
                </SettingsLi>

        </SettingsListContainer>
    )
}

export default SettingsComponent

const SettingsLiInvite = styled(SettingsLi)`
    background-color: white;
    color: var(--themeColor);
    border: 3px solid var(--themeColor);
    /* background-color: var(--themeColor);
    color: white;
    border: 3px solid var(--themeColor); */

    &:hover {
        background-color: var(--themeColor);
        color: white;
        border: 3px solid var(--themeColor);
        /* background-color: white;
        color: var(--themeColor);
        border: 3px solid var(--themeColor); */
    }

`


const SettingsListContainer = styled.div`
    padding-top: 30px;
    width: fit-content;
    border-right: 1px solid var(--borderColor);
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    height: fit-content; 

    @media (max-width: 768px) {
        padding-right: 5px;
        width: 70px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 2px;
        margin-right: 5px;
        margin-left: -7px;
        //hide scrollbar
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
        ::-webkit-scrollbar { /* WebKit */
            width: 0;
            height: 0;
        }

    }
`
const SettingsWrap = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--lightThemeFontColor);
`

const SettingsTitle = styled.div`
    display: block;
    text-align: left;
    padding-left: 10px;
    font-size: 20px;
    color: var(--fontColorGrey);
    font-family: 'Arial', sans-serif;
    margin-bottom: 15px;
    @media (max-width: 768px) {
        text-align: center;
        padding-left: 5px;
        font-size: 14px;
        margin-bottom: 10px;
    }
`

const SettingsItemTitle = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    @media (max-width: 768px) {
        display: none;
    }
`
