import React, {useEffect} from 'react'
import styled from 'styled-components'
import { FileModal, ButttonWrap, FileUploadButton } from 'components/files/files.styled'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { setViewFileModal, setFolderToAddFile, resetUploadState, moveFile, setSelectedFile } from 'features/files/fileSlice'
import Select  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import SpinnerSmall from 'components/SpinnerSmall'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


export default function MoveFileModal() {

    const dispatch = useDispatch()

    const { selectedFile, selectedFolder, isFolderUpdating, isFolderUpdatedSuccess, collectionFiles, folderToAddFile } = useSelector((state) => state.file)
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleClose = () => {
        dispatch(setViewFileModal({
            modal: 'viewMoveFileModal',
            view: false,
        }))
    }

    const handleSelectFolder = (e) => {
        dispatch(setFolderToAddFile(e.target.value))
    }

    const handleMoveFile = () => {

        // ensure a folder is selected
        if(!folderToAddFile){
            return
        }

        if(selectedFolder){
            if(selectedFolder.files.some(file => file._id === selectedFile._id)){

                dispatch(moveFile({
                    fromFolderId: selectedFolder._id,
                    toFolderId: folderToAddFile,
                    fileId: selectedFile._id,
                    churchId: user.isOrganization ? null : profileChurch._id
                }))

            }            
        }else{
            dispatch(moveFile({
                fromFolderId: null,
                toFolderId: folderToAddFile,
                fileId: selectedFile._id,
                churchId: user.isOrganization ? null : profileChurch._id
            }))
        }   
    }
 
    useEffect(() => {
        if(isFolderUpdatedSuccess){
            dispatch(resetUploadState())
            
            dispatch(setViewFileModal({
                modal: 'viewMoveFileModal',
                view: false,
            }))
        }
    }, [isFolderUpdatedSuccess])

    useEffect(() => { 
        return () => {
            dispatch(setSelectedFile(null))
        }
    }, [])

    return (
        <FileModal>
            <ModalCloseButton
                handler={handleClose}
                top='10px'
                right='10px'
                size='30px'
                />

            <FIH>Move File</FIH>



            <FolderSelect>
                    <InputLabel id="demo-simple-select-label">Folder</InputLabel>

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{width: '100%'}}
                        value={folderToAddFile}
                        onChange={handleSelectFolder} 
                        >
                            {
                                collectionFiles.folders.map((folder, index) => (
                                    <MenuItem 
                                        key={folder._id} 
                                        selected={folderToAddFile === folder._id}
                                        value={folder._id}
                                        >
                                            {folder.title}
                                    </MenuItem>
                                ))
                            }
                    </Select>
                </FolderSelect>

                <ButttonWrap>
                    <FileUploadButton
                        disabled={isFolderUpdating}
                        width={'fit-content'}
                        onClick={handleMoveFile}
                        >
                        {
                            isFolderUpdating ? (
                                <SpinnerSmall />
                            )
                            :
                            (
                                <>
                                    <DriveFileMoveIcon style={{ marginRight: '10px'}} />
                                    <span>Move</span>
                                </>
                            )
                        }
                    </FileUploadButton>                 
                </ButttonWrap>
        </FileModal>
    )
}

const FIH = styled.h3`
    color: var(--lightThemeFontColor);
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
    width: 100%;
    text-align: left;

`

const FolderSelect = styled.div`

    width: 100%; 
    border-radius: 5px;
    /* border: 1px solid var(--borderColor); */
    margin-top: 10px;

`