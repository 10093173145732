import axios from 'axios'

import { baseUrl } from '../constants'
const URL = baseUrl

const API_URL = '/api/users/'
const API_URL_REG_CHURCH = '/api/users/registerChurch'

const API_URL_LOGOUT = '/api/users/logoutUser'
const URL_V_U = '/api/users/verifyUsernameAvailable'
const URL_V_E = '/api/users/verifyEmailAvailable'
const URL_SEND_EMAIL_VERIFY = '/api/users/sendVerificationEmailCode'
const URL_VERIFY_CODE = '/api/users/verifyEmailCode'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

// Register user
const register = async (userData) => {
  const response = await axios.post(URL+API_URL, userData, {withCredentials: true})

  // if (response.data) {
  //   localStorage.setItem('user', JSON.stringify(response.data))
  // }

  // we want to make the user login after account is created. 01/11/2023

  return response.data
}

// Register Organization
const registerChurch = async (data) => {
  const response = await axios.post(URL + API_URL_REG_CHURCH, data, {withCredentials: true})

  // if (response.data) {
  //   localStorage.setItem('user', JSON.stringify(response.data))
  // }

  // we want to make the user login after account is created. 01/11/2023

  return response.data
}


// Login user
const login = async (userData) => {
  const response = await axios.post(URL+API_URL + 'login', userData, {withCredentials: true} )

  if (response.data) {
    let data = response.data
    // data.myData.password = null
    localStorage.setItem('user', JSON.stringify(data))
  }

  return response.data
}

// Login user
const loginChurch = async (userData) => {
  const response = await axios.post(URL+API_URL + 'loginChurch', userData, {withCredentials: true} )

  if (response.data) {

    let data = response.data

    // data.myData.password = null

    localStorage.setItem('user', JSON.stringify(data))
  }

  return response.data
}


// Login user
const logoutUser = async (_, token) => {
  localStorage.removeItem('user')

  const response = await axios.post(URL+API_URL_LOGOUT, _, config(token))
  
  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

const logoutUserFaraSesiune = async () => {
  localStorage.removeItem('user')
}

const verifyUsernameAvailable = async (data) => {
  const response = await axios.post(URL+URL_V_U, data)
  return response.data
}

const verifyEmailAvailable = async (data) => {
  const response = await axios.post(URL+URL_V_E, data)
  return response.data
}


const sendVerificationEmailCode = async (data) => {
  const response = await axios.post(URL+URL_SEND_EMAIL_VERIFY, data)
  return response.data
}

const verifyEmailCode = async (data) => {
  const response = await axios.post(URL+URL_VERIFY_CODE, data)
  return response.data
}

const getUsZipCodeData = async (data) => {
  const response = await axios.post(URL+'/api/users/getUsZipCodeData', data)
  return response.data
}

const verifyRegisterData = async (data) => {
  const response = await axios.post(URL + '/api/users/verifyRegisterData', data)
  return response.data
}


const updateMyMarketLocation = async (data, token) => {
  const response = await axios.post(URL+'/api/users/updateMyMarketLocation', data, config(token))
  
  if (response.data) {
    let currentLocalStorageData = JSON.parse(localStorage.getItem('user'))

    currentLocalStorageData.myMarketLocation = response.data.myMarketLocation

    localStorage.setItem('user', JSON.stringify(currentLocalStorageData))
  }
  
  return response.data
}

const addAc = async (data, token) => {
  const response = await axios.post(URL+'/api/users/addAc', data, config(token))
  return response.data
}

const updatePreferredLanguage = async (data, token) => {
  const response = await axios.post(URL+'/api/settings/updatePreferredLanguage', data, config(token))
  return response.data
}

const pastravzta = async (data, token) => {
  const response = await axios.post(URL+'/api/users/pastravzta', data, config(token))
  return response.data
}

const authService = {
  register,
  registerChurch,
  logout,
  logoutUser,
  login,
  loginChurch,
  verifyUsernameAvailable,
  verifyEmailAvailable,
  sendVerificationEmailCode,
  verifyEmailCode,
  logoutUserFaraSesiune,
  getUsZipCodeData,
  updateMyMarketLocation,
  addAc,
  updatePreferredLanguage,
  pastravzta,
  verifyRegisterData

}

export default authService
