import React from 'react'
import { 
    ItemBox, PremiumContainer, StartButtonWrapper, 
    PremiumTitle, PremiumPrice, CheckBoxIcon,
    StartPlanButton, LineHor, StartTrialContainer, STHeader
 } from '../settings.styled'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectPlan, setViewSubscribeModal, setSubscribeIntent } from 'features/billing/billingSlice'
import SpinnerSmall from 'components/SpinnerSmall' 
import dayjs from 'dayjs';
import { FaCheckCircle } from "react-icons/fa";
import { toast } from 'react-toastify'
import styled from 'styled-components';

export default function StartSubscription() {
    const dispatch = useDispatch()
    const { 
        selectedPlan, 
        trialAvailable,
        trialEndDate,
        paymentIntentLoading,
    } = useSelector((state) => state.billing)

    const gracePeriodEndDate = dayjs(trialEndDate).add(3, 'days').format('MMMM D, YYYY') 
    const today = dayjs()
    const gracePeriodEnd = dayjs(gracePeriodEndDate)  
    const date = dayjs(trialEndDate).format('MMMM D, YYYY')

    const handleConfirmSelectedPlan = () => {

        if(!selectedPlan){
            toast.error('Please select a plan to continue.')
            return
        }

        dispatch(setSubscribeIntent({
            selectedPlan: selectedPlan, 
        }))
        dispatch(setViewSubscribeModal())
    }

    return (
        <StartTrialContainer>  
            <STHeader>
                <span>Empower Your Church. Engage Your Community.</span>
                {
                    trialAvailable &&
                    <span>Start Your Free Trial Today!</span>
                }
            </STHeader>
            <PremiumContainer>
                <ItemBox
                    onClick={() => { dispatch(setSelectPlan('monthly')) }}
                    selected={selectedPlan === 'monthly'}
                    >
                        {
                            selectedPlan === 'monthly' &&
                            (
                                <CheckBoxIcon> <FaCheckCircle size={25}  /> </CheckBoxIcon>
                            )
                        }

                    <PremiumTitle> Premium  </PremiumTitle>
                    <PremiumPrice>
                        <span> <b>$15</b> <span>/ month</span> </span> 
                        <span>billed monthly</span> 
                    </PremiumPrice> 
                </ItemBox>
                <ItemBox
                    onClick={() => { dispatch(setSelectPlan('yearly')) }}
                    selected={selectedPlan === 'yearly'}
                    >
                        {
                            selectedPlan === 'yearly' &&
                            (
                                <CheckBoxIcon> <FaCheckCircle size={25} /> </CheckBoxIcon>
                            )
                        } 
                    <PremiumTitle> Premium  </PremiumTitle>
                    <PremiumPrice>
                        <span>  <b>$12</b> <span>/ month</span>  </span> 
                        <span>$144 billed yearly</span>  
                    </PremiumPrice>
                </ItemBox> 
            </PremiumContainer> 
            {
                trialAvailable ? (
                    <InfoWrapQW>
                        Start your free 30 day trial to unlock all the features of Praysely Premium that 
                        will help you engage your community and empower your church.
                        Cancel anytime!
                    </InfoWrapQW>
                )
                :
                (
                    <InfoWrapQW>
                        Start your Praysely Premium subscription today to unlock all the features that will help you engage your community and empower your church.
                    </InfoWrapQW>
                )
            }

            <StartButtonWrapper>
                <StartPlanButton
                    disabled={paymentIntentLoading}
                    onClick={handleConfirmSelectedPlan}
                    >
                    {
                        paymentIntentLoading ?
                        (
                            <SpinnerSmall />
                        )
                        : trialAvailable ? (
                            <span>Start Free Trial</span>
                        )
                        :
                        (
                            <span>Start Subscription</span>
                        )
                    } 
                </StartPlanButton>
            </StartButtonWrapper> 
        </StartTrialContainer>
    )
}

const InfoWrapQW = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: var(--lightThemeFontColor);
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 1.5;
`