import '../../css/components/userProfile/notAMember.css'
import { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import { sendJoinRequest } from '../../features/profileChurch/profileChurchSlice'
import {  checkRequests } from '../../features/requests/requestSlice'
import Loader from 'loaders/Loader'
import styled from 'styled-components' 
import SpinnerSmall from 'components/SpinnerSmall'
import PublicNonMemberViewItems from 'components/churchProfile/PublicNonMemberViewItems' 

function NotAMember() {
    const dispatch = useDispatch()
    
    const { 
        profileChurch, 
        isChurchProfileError, 
        isChurchProfileSuccess, 
        isChurchProfileLoading, 
        sendingRequest, 
        requestSentSuccess 
    } = useSelector((state) => state.profileChurch)

    const {  
        isRequested, 
        isRequestCheckSuccess,
        isRequestCheckLoading,
        isRequestCheckError,
    } = useSelector((state) => state.requests)

    // // check if request has already been sent
    useEffect(() => {
            dispatch(checkRequests({ profileId: profileChurch._id }))
    }, [dispatch, profileChurch._id])

    const handleJoinClick = () => {
        dispatch(sendJoinRequest({ profileId: profileChurch._id }))

    }

    useEffect(() => { 
        
        dispatch(checkRequests({ churchId: profileChurch._id }))
        
    }, [ ])

    return (
        <NotAMemberContainer>
            {
                isChurchProfileLoading ? 
                (
                    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center'}}>
                        <Loader />
                    </div> 
                )
                :
                (
                    <NonMemberMainView>
                        <NotAMemberTop>
                            <h1> You are not a member of {profileChurch.fullName} </h1>
                            {
                                isRequestCheckLoading ?
                                (
                                    <SpinnerSmall />
                                )
                                :
                                (
                                    isRequested || requestSentSuccess ? 
                                    (
                                        <JoinBtn
                                            disabled={true}
                                            >
                                            <span> Request sent </span>
                                        </JoinBtn>
                                    )
                                    :
                                    (
                                        <JoinBtn 
                                            disabled={sendingRequest} 
                                            onClick={handleJoinClick}
                                            >
                                            {
                                                sendingRequest ?
                                                (
                                                    <SpinnerSmall />
                                                )
                                                :
                                                (
                                                    "Request to join"
                                                )
                                            }
                                        </JoinBtn>
                                    )
                                )
                            }
                       
                        </NotAMemberTop>
                        
                        { isChurchProfileSuccess && profileChurch.public && (
                            <PublicNonMemberViewItems
                                width="100%"
                                profileImg={profileChurch.public.profileImg ? profileChurch.public.profileImg : null}
                                location={profileChurch.public.location ? profileChurch.public.location : null}
                                fullName={profileChurch.fullName}
                                description={profileChurch.public.description ? profileChurch.public.description : null}
                                stats={profileChurch.public.stats ? profileChurch.public.stats : null}
                                bulletin={profileChurch.public.bulletin ? profileChurch.public.bulletin : null}
                                people={profileChurch.public.people ? profileChurch.public.people : null}
                                />                        
                            )
                        }




                    </NonMemberMainView>
                )
            }
        </NotAMemberContainer>
    )
}

export default NotAMember

const NotAMemberContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    justify-content: flex-start;
    align-items: center;
`


const NonMemberMainView = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: flex-start;

`

const NotAMemberTop = styled.div`
    display: flex;
    flex-direction: column;  
    justify-content: flex-start;
    align-items: center; 
    h1{
        font-size: 20px;
        font-weight: 400;
        margin-top: 30px;
        margin-bottom: 20px;
        font-family: 'Roboto', sans-serif;
        color: var(--dFontColor);
    } 
`



const JoinBtn = styled.div`
    height: fit-content;
    width: 200px;
    padding: 15px;
    background-color: ${props => props.disabled ? 'rgb(151, 190, 151)' : 'rgb(1, 161, 1)'};
    color: aliceblue;
    border-radius: 4px;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    &:hover{
        cursor: pointer;
        background-color: rgb(1, 161, 1);
        transition: 0.3s;
    }
`
const RequestSent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
        font-size: 15px;
        font-weight: 400;
        margin-top: 10px;
        font-family: 'Roboto', sans-serif;
        color: var(--dFontColor);
    } 
`