import './messagePanel.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, getMoreMessages, messageReset } from '../../features/messenger/messengerSlice'; 
import MessageItem from './MessageItem';
import NewMessageForm from './NewMessageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { resetAlreadyExists, deleteConversation, resetDeleteData } from '../../features/messenger/conversationSlice';
import { updateMessageNotifs } from '../../features/notifs/notifSlice';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGetWindowSize from 'hooks/useGetWindowSize';
import Loader from 'loaders/Loader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useToggleDropdown from 'hooks/useToggleDropdown'; 
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import { Modal } from '@mui/material';
import ConfirmModal from 'components/modals/ConfirmModal';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';


function MessagePanel() {
  const ref = useRef()
  const dispatch = useDispatch()
  let { convId } = useParams();
  let { mm } = useParams();
  const navigate = useNavigate()
  const [width, height] = useGetWindowSize()
  const [isDropped, setDropdown] = useToggleDropdown(ref)

  const handleDropdown = () => {
    setDropdown.toggle(!isDropped)
  }
  const { user } = useSelector(state => state.auth)  

  const { 
    messages, isMessagesError, isLoadingMore, isMessagesSuccess, 
    isMessagesLoading, messagesMessage,
    totalMessages, totalPages, page, conversationType,
    pagingCounter,   hasNextPage, nextPage, hiddenUsers

  } = useSelector(state => state.messages)

  const { newMessages } = useSelector( state => state.notifs )


  const { viewConversation, currentMessagePanel, deleteConversationLoading, deleteConversationSuccess } = useSelector( state => state.conversations )  
  const { viewConfirmModal, confirmModalData } = useSelector(state => state.modal)

  const conversationDetails = JSON.parse(localStorage.getItem(`${convId}`))

  const handleLoadMore = () => {
    if(hasNextPage){  
      dispatch(getMoreMessages({ 
        conversationId: convId,
        isMember2member: mm ? true : false,
        currentPage: nextPage,
      }))
    }
  }

  useEffect(() => {
    dispatch(getMessages({ 
      conversationId: convId,
      isMember2member: mm ? true : false,
    }))

    return () => { 
      dispatch(resetAlreadyExists())
      dispatch(messageReset())
    }

  },[dispatch, convId, mm])


  const handleDeleteConversation = () => {
    // set confirm modal data
    dispatch(setConfirmModalData({
      text: 'Are you sure you want to leave this conversation?',
      extraInfo: 'This removes the conversation from your inbox and you will no longer receive messages from this conversation. The other user will still see the conversation and messages in their inbox.',
      handler: () => dispatch(deleteConversation({ 
        conversationId: convId,
        conversationType: conversationType,
      })),
      confirmBtnBackgroundHover: '#b62309',
      loadingState: deleteConversationLoading,
      conditionToCloseModal: deleteConversationSuccess,
      thingsToResetAfterClose: () => dispatch(resetDeleteData())
    }))

    dispatch(setViewConfirmModal(true))
 
  }

  const handleResetAfterClose = () => {
    dispatch(resetDeleteData())
    
    // navigate to messages
    navigate('/messages')

  }

  useEffect(() => {
    if(newMessages.includes(convId)){
      // remove the conversation from the new messages array
      dispatch(updateMessageNotifs({
        convId: convId
      }))
    }
  }, [])

  // add an unmount function to remove a newly created covnersation if the user does not send a message

  return (
    <MessagePanelContainer windowHeight={height}> 
      <BackToMessages onClick={() => navigate('/messages')} > 
        <ArrowBackIcon /> <span>Back to Messages</span>
      </BackToMessages>

      <MPTop> 
        <div className="mpTopName">{conversationDetails && (`Conversation with: ${conversationDetails.name}`)} </div>
        <div 
          ref={ref}
          style={{cursor: 'pointer'}}
          onClick={handleDropdown}
          className='mpTopIcon'
          > 
          <MoreHorizIcon />
        </div>

        {
          isDropped && (
            <ConversationDropdown>
              <ConvDropItem
                onClick={handleDeleteConversation}
                > 
                <PersonRemoveIcon />
                <span>Leave Conversation </span>
              </ConvDropItem>
            </ConversationDropdown>
          )
        }


      </MPTop>
      <MessageContent>

        
        

      {
        isMessagesLoading && !isLoadingMore ? 
        (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
            >
            <Loader />
          </div>
            
        )
        : isMessagesSuccess && messages &&
        (
          

            mm ? (
              messages.map(ele => (
                <MessageItem 
                  key={ele._id}
                  messageId={ele._id} 
                  image={ele.image}
                  isMine={ele.authorId === user._id} 
                  text={ele.text}
                  dateSent={ele.createdAt} 
                  />
              ))
            )
            :
            (
              user.isOrganization ? 
              (
                messages.map(ele => (
                  <MessageItem 
                    key={ele._id}
                    messageId={ele._id} 
                    isMine={ele.authorId === user._id} 
                    text={ele.text}  
                    image={ele.image}
                    // sender={ele.user._id} 
                    dateSent={ele.createdAt} 
                    />
                ))
              )
              :
              (
                messages.map(ele => (
                  <MessageItem 
                    key={ele._id}
                    messageId={ele._id} 
                    isMine={ele.authorId === user._id} 
                    text={ele.text}  
                    // sender={ele.user._id} 
                    dateSent={ele.createdAt} 
                    />
                ))
              )
            )
          )
        } 

        { (isMessagesSuccess && !isMessagesLoading && messages && hasNextPage && !isLoadingMore) && (
          <LoadMoreBtn 
            disabled= {isLoadingMore}
            onClick={handleLoadMore}
            > 
            Load more messages
          </LoadMoreBtn>)
        }

        {
          isLoadingMore && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center'
                
              }}
              >
              <Loader />
            </div>
          )
        }
  
      </MessageContent>
      <NewMessageForm id={convId}/>

        <Modal
          open={viewConfirmModal}
          style={{display:'flex', alignItems:'center', justifyContent:'center'}}
          onClose={() => dispatch(setViewConfirmModal(false))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ConfirmModal 
            text={confirmModalData.text}
            extraInfo={confirmModalData.extraInfo}
            handler={confirmModalData.handler}
            confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
            conditionToCloseModal={deleteConversationSuccess}
            doSomethingAfterClose={true}
            resetAfterClose={handleResetAfterClose}
            loadingState={deleteConversationLoading}
            />

        </Modal>


    </MessagePanelContainer>
  )
}

const ConversationDropdown = styled.div`

  position: absolute;
  top: 45px;
  right: 35px;
  width: 200px;
  padding: 10px;
  height: fit-content; 
  background-color: white;
  border-radius: 10px 0px 10px 10px;
  box-shadow: 0px 0px 11px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 22;
`

const ConvDropItem = styled.div`

  width: 100%;
  height: fit-content;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: .4s;
  font-size: 12px;
  svg{
    margin-right: 10px;
  
  }

  &:hover{
    background-color: rgb(236, 236, 236)
  }
`


const MessagePanelContainer = styled.div`
  min-width: 600px; 
  height: calc(100vh - 55px);
  border-right: 1px solid var(--borderColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  @media (max-width: 1268px) {
      min-width: 400px; 
      /* height: ${props => props.windowHeight ? (`${props.windowHeight -160}px`):('50vh') }; */
      height: calc(100vh - 75px);
      width: 450px;
      box-shadow: none;
      border-radius:0px;
      margin: 0px;
      
  }  
  @media (max-width: 768px) {
      min-height: unset;
      height: ${props => props.windowHeight ? (`${props.windowHeight -160}px`):('50vh') }; 
      min-width: unset;
      width: 100%;
      box-shadow: none;
      border-radius: 0px;
      margin: 0px;
      border-right: none;
      height: calc(100vh - 75px);
  }
`

const BackToMessages = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    margin: 7px 0px 7px 0px;
    width: fit-content;
    border-radius: 8px;
    color: #595959;
    cursor: pointer;
    transition: .4s;
    &:hover{
      border: 1px solid green;
      background-color: green;
      color: white
    }
  }
`

const MPTop = styled.div`
  height: 70px;
  color: var(--dFontColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  @media (max-width: 768px) {
    height: 30px;
    border-top: 1px solid var(--borderColor);
    border-bottom: none;
    padding: 20px;
    background-color: #ffffff;
  }
`

const MessageContent = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    flex: 1;
    height: 90%;
    background-color: white;
    padding: 8px;
    overflow-y: scroll; 
    
    @media (max-width: 768px) {
      height: 70%;
      box-shadow: none
    }
`

const LoadMoreBtn = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: fit-content;
  margin-bottom: 20px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: var(--lightThemeFontColor);
  &:hover{
    transition: all .4s;
    background-color: rgb(230,230,230)
  }
`

export default MessagePanel