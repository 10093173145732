import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import requestService from './requestService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  requestsReceived: [],
  requestsSent: [],
  isRequested: false,
  request: '',

  currentReqId: '',

  isRequestCheckSuccess: false,
  isRequestCheckLoading: false,
  isRequestCheckError: false,
  requestCheckmessage: '',

  isAcceptSuccess: false,
  isAcceptLoading: false,
  isAcceptError: false,
  acceptmessage: '',

  isDeleteSuccess: false,
  isDeleteLoading: false,
  isDeleteError: false,

  isRequestSuccess: false,
  isRequestLoading: false,
  isRequestError: false,
  requestmessage: '',

  isRequestGetSuccess: false,
  isRequestGetLoading: false,
  isRequestGetError: false,
  requestGetmessage: ''
}

//for users who have mad a join request --> ** not for churches **
export const getRequestsSent = createAsyncThunk(
  'requests/getRequestsSent',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await requestService.getRequestsSent(token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)
//for churches to see requests received --> ** not for users **
export const getRequestsReceived = createAsyncThunk(
  'requests/getRequestsReceived',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await requestService.getRequestsReceived(token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//for churches accept requests received --> ** not for users **
export const acceptRequest = createAsyncThunk(
  'requests/acceptRequest',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await requestService.acceptRequest(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//for churches accept requests received --> ** not for users **
export const deleteRequest = createAsyncThunk(
  'requests/deleteRequest',
  async (userId, thunkAPI) => {
    try {
      
      const token = thunkAPI.getState().auth.user.token
      return await requestService.deleteRequest(userId, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//checks if logged in user has already requested this church
export const checkRequests = createAsyncThunk(
  'requests/checkRequests',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await requestService.checkRequests(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    resetRequests: (state) => initialState,
    setCurrentLoadingRedId: (state, action) => {
      state.currentReqId = action.payload
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestsSent.pending, (state) => {
        state.isRequestGetLoading = true
      })
      .addCase(getRequestsSent.fulfilled, (state, action) => {
        state.isRequestGetLoading = false
        state.isRequestGetSuccess = true
        state.requestsSent = action.payload
      })
      .addCase(getRequestsSent.rejected, (state, action) => {
        state.isRequestGetLoading = false
        state.isRequestGetError = true
        state.requestGetmessage = action.payload
      })
      //----------------------------------------------------------------
      .addCase(getRequestsReceived.pending, (state) => {
        state.isRequestGetLoading = true
      })
      .addCase(getRequestsReceived.fulfilled, (state, action) => {
        state.isRequestGetLoading = false
        state.isRequestGetSuccess = true
        state.requestsReceived = action.payload.requests
      })
      .addCase(getRequestsReceived.rejected, (state, action) => {
        state.isRequestGetLoading = false
        state.isRequestGetError = true
        state.requestGetmessage = action.payload
      })
      //----------------------------------------------------------------
      .addCase(acceptRequest.pending, (state) => {
        state.isAcceptLoading = true
      })
      .addCase(acceptRequest.fulfilled, (state, action) => {
        state.isAcceptLoading = false
        state.isAcceptSuccess = true
        state.requestsReceived = state.requestsReceived.filter(
          (ele) => ele._id !== action.payload.deletedReqId
        )
      })
      .addCase(acceptRequest.rejected, (state, action) => {
        state.isAcceptLoading = false
        state.isAcceptError = true
        state.acceptmessage = action.payload
      })
      //----------------------------------------------------------------
      .addCase(checkRequests.pending, (state) => {
        state.isRequestCheckLoading = true
      })
      .addCase(checkRequests.fulfilled, (state, action) => {
        state.isRequestCheckLoading = false
        state.isRequestCheckSuccess = true
        state.isRequested = action.payload.isRequested
      })
      .addCase(checkRequests.rejected, (state, action) => {
        state.isRequestCheckLoading = false
        state.isRequestCheckError = true
        state.requestCheckmessage = action.payload
      })
      //----------------------------------------------------------------
      .addCase(deleteRequest.pending, (state) => {
        state.isDeleteLoading = true
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.isDeleteLoading = false
        state.isDeleteSuccess= true

        if(action.payload.type === 'delete'){ 
          state.requestsReceived = state.requestsReceived.filter(
            (ele) => ele._id !== action.payload.deletedReqId
          )
        }else if(action.payload.type === 'cancel'){
          state.requestsSent = state.requestsSent.filter(
            (ele) => ele._id !== action.payload.deletedReqId
          )
        }
      })
      .addCase(deleteRequest.rejected, (state, action) => {
        state.isDeleteLoading = false
        state.isRequestError = true
        state.requestmessage = action.payload
      })
  }
})

export const { resetRequests, setCurrentLoadingRedId } = requestSlice.actions
export default requestSlice.reducer
