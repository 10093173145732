import React from 'react'
import { InputItemWrap, InputLabel, DHA, AuthButton, ForgotPasswordButton, FormContainer, LoginHeader, FormMain, LoginFooter, FormMainHeader, InputFieldItem } from 'pages/auth/auth.styled'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify' 
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { login, reset, loginOrganization } from '../../features/auth/authSlice'
import { setForgotPasswordUserType, setViewForgotPassword, setUserType } from '../../features/auth/authSlice' 
import SpinnerSmall from '../../components/SpinnerSmall'
import useKeypress from '../../hooks/useKeypress' 
import ReCAPTCHA from 'react-google-recaptcha'
import {useState, useEffect, useRef} from 'react'
import { FaCheck } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import SelectUserType from './SelectUserType'



export default function LoginForm() {

    const dispatch = useDispatch()
    const recaptcha = useRef();
    const email = useRef();
    const password = useRef();
    const navigate = useNavigate() 
    const { user, userType, isLoginLoading, isError, isLoginSuccess, message } = useSelector( (state) => state.auth )
 
    const handleNavigate = () => {
        navigate('/register')
    }

    const handleLogin = () => {
        const token = recaptcha.current.getValue(); 

        if(!token){
            toast.error('Please complete the captcha')
            return
        }

        if(userType === 'church'){
            dispatch(loginOrganization({
                email: email.current.value,
                password: password.current.value,
                captchaValue: token
            }))
        }else{ 
            dispatch(login({ 
                email:  email.current.value,
                password: password.current.value,
                captchaValue: token 
            }))
        }
    }

    const handleForgotPassword = () => {
        dispatch(setForgotPasswordUserType(userType))
        dispatch(setViewForgotPassword(true))
    }

    useEffect(() => {
        if (!user) {  navigate('/login') }
        if (isError) { toast.error(message) }

        if ( !isLoginLoading && (isLoginSuccess || user)) { 
            if(userType === 'church'){
                navigate(`/${user.name}`)
            }else{
                navigate(`/home`)
            }
        }
        else if( !isLoginLoading && (isLoginSuccess || user) && user.isChurch){ 
            navigate('/home') 
        }
 
    }, [ isLoginLoading, user, isError, isLoginSuccess, message, navigate, dispatch])
    

    return (
        <FormContainer>
            <LoginHeader>
                <img
                    style={{ width: "100px", height: "40px" }} 
                    src={require("../../prayselyLogo.png")} alt="logo" />
            </LoginHeader>
            <FormMain>  
                <FormMainHeader>
                    <h1>Welcome Back!</h1>
                    <p>Sign in to your account</p>
                </FormMainHeader>
 
                <SelectUserType />

                {
                    userType !== 'none' && (
                        <>
                            <InputItemWrap>
                                <InputLabel>
                                    Email
                                </InputLabel>
                                <InputFieldItem>
                                    <input
                                        ref={email} 
                                        type="text" 
                                        placeholder="Email" 
                                        /> 
                                </InputFieldItem>
                            </InputItemWrap>
                            <InputItemWrap>
                                <InputLabel>
                                    Password
                                </InputLabel>
                                <InputFieldItem>
                                    <input
                                        ref={password}
                                        type="password" 
                                        placeholder="Password" 
                                        />
                                </InputFieldItem>
                            </InputItemWrap>
            
                            <ForgotPasswordButton
                                onClick={handleForgotPassword}
                                > <span>Forgot Password?</span> </ForgotPasswordButton>
                            <ReCAPTCHA 
                                ref={recaptcha}
                                sitekey={"6LfmM8QqAAAAANqh0Ia0RyQIGjJ3hHDK5EB-f6Xr"}
                                />
            
            
                            <AuthButton onClick={handleLogin} >
                                    Login 
                                    as <span style={{textTransform: 'capitalize'}}>{userType }</span>
                            </AuthButton>
                            
                            <DHA>
                                <span>Don't have an account?</span>
                                <p
                                    onClick={handleNavigate}
                                    >Register
                                </p>
                                
                            </DHA>
                        </>
                    )
                }

            </FormMain>
            <LoginFooter>
                <span> &copy; 2025 Praysely, Inc </span>
            </LoginFooter>
        </FormContainer>
    )
}
