import { useEffect, useState } from "react";
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux"; 
import { attachPaymentMethod, setViewAddCardModal, removeCardError } from 'features/billing/billingSlice'
import {
  CardNumberElement , 
  CardExpiryElement,
  CardCvcElement,
  useStripe,  
  useElements 
} from '@stripe/react-stripe-js';
import Loader from "loaders/Loader";
import Switch from '@mui/material/Switch'; 
import SpinnerSmall from "components/SpinnerSmall"; 
import { CiCreditCard1 } from "react-icons/ci";


const ELEMENT_OPTIONS = {
  style: { 
    base: { 
      fontSize: '18px',
      border: '1px solid red',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: { 
      color: '#9e2146'
    }
  },
};

export default function AddCardModal(props) {
  const dispatch = useDispatch()
  const stripe = useStripe();
  const elements = useElements();   
  const { isSetPaymentMethodsLoading, isSetPaymentMethodsError } = useSelector((state) => state.billing)
  const [postal, setPostal] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false); 
  const handleChange = (event) => { setChecked(event.target.checked) }; 

  const handleSubmit = async ( event ) => { 

    event.preventDefault(); 

    if (!stripe || !elements) { return; }
    const card = elements.getElement(CardNumberElement);
    if (card == null) { return; }

    setIsLoading(true);

    const payload = await stripe.createPaymentMethod({
      type: 'card', card, billing_details: { address: { postal_code: postal } }, 
    }); 

    if (payload.error) {  
      setIsLoading(false);
    } else { 
      setIsLoading(false);
      dispatch(attachPaymentMethod({
        setDefault:  checked ? true : false,
        paymentId: payload.paymentMethod.id
      }))  
    }
  };
  
  useEffect(() => {
    // on umnount
    return () => {
      dispatch(removeCardError())
    }
  }, [])

  return (
    <AddCardModalContainer>
      <ACModalContainer loading={isSetPaymentMethodsLoading} >
 
 
        <CloseBtn onClick={() => dispatch(setViewAddCardModal())}>
          <CloseIcon style={{color: '#909090'}} />
        </CloseBtn>
   

        <CardForm>
          <CardLabel > 
            <CiCreditCard1 style={{ fontSize: '40px', color: '#ccc', marginRight: '10px' }} />
            <b> Add a new payment method </b>
          </CardLabel>
                
          { isSetPaymentMethodsLoading ?
            (
              <LoaderWrap> < Loader /> </LoaderWrap> 
            )
            :
            ( 
              <ElementsForm > 
                <CardItemWrap

                  >
                  <label htmlFor="cardNumber">Card Number</label> 
                    <CardNumberElement
                      id="cardNumber" 
                      className="cardInput"
                      options={ELEMENT_OPTIONS}    

                    />            

                </CardItemWrap>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    width: '100%'
                  }}
                  >
                  <CardItemWrap style={{ width: '50%' }} >
                    <label htmlFor="expiry">Card Expiration</label>
                    <CardExpiryElement
                      id="expiry" 
                      className="cardInput"
                      options={ELEMENT_OPTIONS}
                    />
                  </CardItemWrap>

                  <CardItemWrap style={{ width: '50%' }} >
                    <label htmlFor="cvc">CVC</label>
                    <CardCvcElement
                      id="cvc" 
                      className="cardInput"
                      options={ELEMENT_OPTIONS}
                    />
                  </CardItemWrap>
                </div>
                <CardItemWrap style={{ width: '50%' }} >
                  <label htmlFor="postal">Postal Code</label>
                  <input
                    id="postal"
                    className="cardInput"
                    required
                    placeholder="12345"
                    value={postal}
                    onChange={(e) => {
                      setPostal(e.target.value);
                    }}
                  /> 
                </CardItemWrap>
              </ElementsForm>
            )
          } 

          {
            isSetPaymentMethodsError && (
              <CardError> Error occured </CardError>
            )
          }

          <CardLower> 
            <SwitchWrap>
              <CheckedText enabled={checked}> { checked ? ('Setting as default') : ('Set this card as default payment method') } </CheckedText> 
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />  
            </SwitchWrap>  
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end', 
              }}
              >
              <img src='/stripe-logo.svg' alt='stripe-logo' style={{  width: '110px' }} />
            
              <AddCardBtn 
                onClick={handleSubmit} 
                disabled={isSetPaymentMethodsLoading}
                >
                  { isSetPaymentMethodsLoading || isLoading ? 
                    (
                      <SpinnerSmall  color='white' size={20}  />
                    )
                    :
                    (
                      'Add Payment Method'
                    )
                  }
              </AddCardBtn>
            </div>
          </CardLower>
        </CardForm>  
      </ACModalContainer>
    </AddCardModalContainer>
  )
}

const CardError = styled.div`

  background-color: red;
  color: white;
  padding: 9px 20px;
  border-radius: 5px;
  margin-top: 14px;
  width: fit-content;
  font-size: 13px;

`

const CardItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 5px;
  justify-content: flex-start;

`

const ElementsForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label{
    font-size: 14px;
  } 

`

const AddCardModalContainer = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
`


const ACModalContainer = styled.div`
    background-color: white;
    width: 500px;
    min-height: fit-content;
    height: fit-content; 
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor:  ${props => props.loading ? 'wait':'auto'}; 
    @media (max-width: 768px) {
        width: 95%;
        border-radius: 7px;
    }
    @media (max-width: 568px) {
        width: 95%;
        border-radius: 7px;
    }

`
 
 

const CloseBtn = styled.div`
    position: absolute;
    right: 20px;
    top: 20px; 
    font-size: 13px;
    width: fit-content;
    cursor: pointer; 
`
const LoaderWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

`
 

const CardForm = styled.form`
  color: grey;
  padding: 20px; 
  background-color: white;
  width: 100%;
  border-radius: 10px;
  font-size: larger;
  display: flex;
  flex-direction: column;

  span{
    padding-top: 15px;
    font-size: x-small;
    text-align: left;
  }
`

const AddCardBtn = styled.button`
  transition: all 0.5s;
  pointer-events: ${props => props.disabled ? 'none':'auto'};
  padding: ${props => props.disabled ? '0px 40px' : '0px 20px'};
  outline: none;
  border: none;
  color: white;
  background-color: var(--themeColor);
  align-self: end;
  justify-self: end;
  cursor:  ${props => props.disabled ? 'wait':'pointer'}; 
  margin-top: 20px;
  border-radius: 5px;
  width: fit-content;
  height: 50px;
  white-space: nowrap; 

`
const CardLabel = styled.div`
  margin-bottom: 40px;
  width: 100%; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
`
const CardLower = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  height: fit-content;
  transition: all 0.5s;
`

const SwitchWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

`

const CheckedText = styled.div`
  color: ${props => props.enabled ? '#1a58cc':'grey' };
  font-size: 12px;
  /* color: #1a58cc */
  font-style: ${props => props.enabled ? 'none':'italic' };
`