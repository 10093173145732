import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setRegistrationType } from 'features/auth/authSlice'
import styled from 'styled-components'
import ChurchRegisterForm from 'components/forms/ChurchRegisterForm'
import UserRegisterForm from 'components/forms/UserRegisterForm'

export default function RegisterBody({ status }) {

    const dispatch = useDispatch()
    const {registerType} = useSelector((state) => state.auth)

    const handleTypeClick = (value) => { 
      dispatch(setRegistrationType(value))
    } 
  
    useEffect(()=> { 
      if (localStorage.getItem('registrationType')) {
        dispatch(setRegistrationType(localStorage.getItem('registrationType')))
      }
    }, [])


    return (
        <RegisterContainer
          status={status}
          >
            <RegisterHeading>Register</RegisterHeading>

            <RegisterWrap>
            
                <RegisterTabsWrap>
                  <RegisterTab 
                      onClick={() => handleTypeClick('church')}
                      tabSelected={registerType === 'church' ? (true):(false) }
                      >
                      Church Organization
                  </RegisterTab>                
                  <RegisterTab 
                      onClick={() => handleTypeClick('user')}
                      tabSelected={registerType === 'user' ? (true):(false) }
                      >
                      
                      Individual User
                  
                  </RegisterTab>

                
                </RegisterTabsWrap>

                <RegisterBodyWrap>


                { registerType === 'user' ?
                    (
                    <UserRegisterForm />  
                    )
                    : registerType === 'church' &&
                    (
                    <ChurchRegisterForm />
                    )
                    
                }
                

                </RegisterBodyWrap>
            </RegisterWrap>
        </RegisterContainer>

    )
}
const RegisterContainer =styled.div`

  transition: all 500ms;

  ${({ status }) =>
    (status === 'preEnter' || status === 'exiting') &&
    `
      opacity: 0;
      transform: scale(0.9);
    `}

  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    text-align: center; 
  }
`

const RegisterHeading = styled.h1`
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  color: var(--lightThemeFontColor);
  font-weight: 400;
  @media (max-width: 768px) {
    width: 70%;
    text-align: center;
  }
`


const RegisterTabsWrap = styled.div`
  width: 70%;
  justify-self: flex-start;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 90%;
  }
`

const RegisterTab = styled.div`
  width: 50%;
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.tabSelected ? ('#37bdc2') : ('rgb(210,210,210)')};
  color: ${props => props.tabSelected ? ('white') : ('#525252')};
  @media (max-width: 768px) {
    width: 50vw;
  }
`


const RegisterBodyWrap =styled.div`
  background-color: #37bdc2;
  width: 100%;
  height: fit-content;
  border-radius: 0px 20px 20px 20px;
  box-shadow: #9e9e9ecc 0 0 26px;
  padding: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const RegisterWrap = styled.div`
  min-width: 600px;
  width: fit-content;
  height: fit-content;
  @media (max-width: 768px) {

    min-width: unset;
    width: 100%;
    height: fit-content;

  }
`