import './messageItem.css'
import TimeAgo from 'timeago-react'; 
import { useEffect, useState, useRef } from 'react';
import { BsThreeDots } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import { deleteMessage } from '../../features/messenger/messengerSlice'
import useToggleDropdown from '../../hooks/useToggleDropdown';
import { imgUrl } from 'features/constants';
import styled from 'styled-components';

function MessageItem({ isMine, text, messageId, dateSent, sender, image }) {
    const dispatch = useDispatch()
    const ref1 = useRef(null) //for dots
    const ref2 = useRef(null) // for confirm
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const [isConfirmed, setIsConfirmed] = useToggleDropdown(ref2)
    const [confirm, setConfirm] = useState(false);
    const [showMore, setShowMore] = useState()
    const { isDeleteLoading, isDeleteError, isDeleteSuccess, deleteMessageError } = useSelector(state => state.messages)
    
    const handleDotsClick = () => {
        setIsDropped.toggle(ref1)
    }

    const handleDelete = () => {
        setIsDropped.off(ref1)
        setIsConfirmed.off(ref2)
        dispatch(deleteMessage({ 
            sender: sender,
            messageId: messageId,
        }))

    }   

    const handleClick = (e) => {
        
        setShowMore(state => !state) 
 
    }

    const handleCancelDelete = () => {
        setIsConfirmed.toggle(ref2)
        setIsDropped.toggle(ref1)
    }



    let date = new Date(dateSent)
    return (
        <>

            {
                image &&
                (
                    <ImgContainer
                        isMine={isMine}
                        onClick={handleClick}
                        >
                        <img 
                            src={imgUrl + '/messages/'+image}
                            alt='img'
                            />
                    </ImgContainer>
                )
            }

            {

                text && text.length > 0 &&
                (
                    <div 
                        ref={ref2}
                        className={`mic-${isMine}`} 
                        onClick={handleClick}
                        >
                        <span className='miText'>
                            {
                                text.split('\n').map((subtext) => {
                                    return (
                                        <span>
                                            {subtext}
                                            <br/>
                                        </span>
                                    )
                                })
                            }
                        </span>
                    </div>
                )
            }
            {
                showMore &&
                (



                    <div ref={ref2} className={`showMore-${isMine}`}> 
                        <TimeAgo datetime={date}/>


                        {/* // edit ** Feb 2025
                    // At this time, lets disable the delete feature
                    // so it will be commented out for now.  */}

                        {/* { isMine ? 
                            (
                                <div  className='msgOptions' onClick={handleDotsClick}> <BsThreeDots/></div> 
                            )
                            :
                            (
                                <div className='msgOptions'> {sender.fullName}   </div>
                            )
                        }
                        { isDropped && 
                            (
                                <div  className={`msgDots-${isMine}`}>
                                    {
                                        isConfirmed ? 
                                        (
                                            <li  className='msgDelConfirm'> 
                                                    <span onClick={handleDelete} >Confirm ? </span>
                                                    <p onClick={handleCancelDelete}>cancel</p> 
                                            </li>
                                        )
                                        :
                                        (
                                            <li onClick={() =>  setIsConfirmed.toggle(ref2)} className='msgDelete'> Delete  </li>
                                        )
                                    }
                                </div>
                            )
                        } */}
                    </div>
                )
            } 
        </>
    )
}
export default MessageItem

const ImgContainer = styled.div` 
    width: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.isMine ? 'flex-end' : 'flex-start'};
    margin-bottom: 10px;
 
    img{
        width: 60%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
`