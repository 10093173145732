import React, { useEffect, CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewDirectoryModal } from '../../features/modal/directoryModalSlice'
import { useState } from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants'
import useImageLoader from 'hooks/useImageLoader'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import useToggleDropdown from 'hooks/useToggleDropdown'
import { closeModal, setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice'
import { deleteDirectoryItem } from 'features/directory/directorySlice'
import { useNavigate } from 'react-router-dom'
import {useImageOnLoad} from 'hooks/useImageOnLoad'
import { DotsWrap, DotEle, Dots, DotsDropDown, DotsButton } from '../dots/dots.styled'
import { directoryLanguage } from './directoryLanguage'


function DirectoryCardItem(props) {
    const dispatch = useDispatch() 
    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)
    const [imageSource, { blur }] = useImageLoader(`${imgUrl}/directory/${props.data.image.imagePath1}`, `${imgUrl}/directory/${props.data.image.imagePath2}`)
    const dotsRef = React.useRef()
    const { user, admin } = useSelector(state => state.auth)
    const [isDotsDropped, setIsDotsDropped] = useToggleDropdown(dotsRef)
    const { profileChurch } = useSelector((state) => state.profileChurch)   
    const { isDirectoryDeletedSuccess, isDirectoryDeletedLoading } = useSelector((state) => state.directory)

    const handleDotsDropdown = (e) => {
        e.stopPropagation()
        setIsDotsDropped.toggle(dotsRef) 
    }

    const handleDelete = () => {

        dispatch(setConfirmModalData({
            text: directoryLanguage.confirmDelete[user.preferredLanguage],
            extraInfo: directoryLanguage.confirmDeleteDetails[user.preferredLanguage],
            handler: () => dispatch(deleteDirectoryItem({ 
                id: props.data._id,
                churchId: profileChurch._id
            })),
            confirmBtnBackgroundHover: 'red', 
            loadingState: isDirectoryDeletedLoading,
        }))

        dispatch(setViewConfirmModal(true))

    }

    const handleEdit = () => {

        if(user.isOrganization){
            // since churches might have made more than one directory item,
            // we can allow them to edit the directory item in a modal.
            dispatch(setViewDirectoryModal(
                { 
                    editDirectory: true,
                    data: props.data, 
                }
            ))

        }else{
            navigate(`/settings?section=directory`)
        }
    }

    const handleClick = () => {

        if(isDotsDropped){
            return
        }

        dispatch(setViewDirectoryModal(
            { 
                viewCard: true,
                data: props.data, 
            }
        ))
    }

    useEffect(() => {
        if(isDirectoryDeletedSuccess){
            dispatch(closeModal())
        }
    }, [isDirectoryDeletedSuccess, dispatch])
 
 
    return (
        <DirectoryCardItemContainer 
            onClick={handleClick}  
            onPointerEnter={() => setHovered(true)} 
            onPointerLeave={() => setHovered(false)} 
            >
            
            {(user.isOrganization || admin[profileChurch._id] || user._id === props.data.linkedUserId) && 
                (
                    <> 
                        <DotsButton 
                            style={{ visibility: hovered ? 'visible' : 'hidden' }}
                            ref={dotsRef}  
                            onClick={handleDotsDropdown} 
                            >
                            <MoreHorizIcon />
                        </DotsButton>

                        {
                            isDotsDropped && (
                                <DotsWrap top={'25px'} right={'25px'} >
                                    { (user.isOrganization || admin[profileChurch._id] || user._id === props.data.linkedUserId) && ( 
                                            <DotEle 
                                                style={{color: 'red'}} 
                                                onClick={handleDelete}
                                                > 
                                                    <DeleteOutlineIcon style={{marginRight: '10px'}} /> 
                                                    {directoryLanguage.delete[user.preferredLanguage]}
                                            </DotEle>
                                        )
                                    }

                                    {
                                        user._id === props.data.linkedUserId && (
                                            <DotEle 
                                                onClick={handleEdit}
                                                > 
                                                <EditIcon style={{marginRight: '10px'}} /> 
                                                {directoryLanguage.edit[user.preferredLanguage]}
                                            </DotEle>
                                        )
                                    }  
                                </DotsWrap>
                            )
                        } 
                    </>
                )
            } 
            
            <DirectoryCardImage>
                <img 
                    src={imageSource} 
                    alt={'directory'} 
                    style={{
                        filter: blur ? 'blur(2px)' : 'none', 
                        transition: 'all 1s ease-in-out',
                    }}
                    />

            </DirectoryCardImage>

            <ViewDirectoryInfo>
            <DirectoryInfoItem> {props.data.name} </DirectoryInfoItem>
            </ViewDirectoryInfo>
            {
                hovered && (
                    <ViewDetails>  
                        <OpenInFullIcon />
                    </ViewDetails>
                )
            }

        </DirectoryCardItemContainer>
    )
}
export default DirectoryCardItem


const DirectoryCardItemContainer = styled.div`
    padding: 10px;
    width: 245px;
    height: 240px; 
    color: rgb(94, 94, 94);
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-right: 10px; 
    margin-left: 2px;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;

    &:hover{
        /* transition all width changes on hover */
        transition: all 0.5s;
        box-shadow: 0 0 5px rgb(83, 83, 83); 
        /* show dots on hover */

        ${DotsWrap}{
            display: flex;
        }
    }
    @media (max-width: 768px) {
        padding: 8px;
        min-width: 180px;
        width: 20%;
        margin: 5px; 
        flex-basis: 20%;
        height: 220px;
    }
`

const ViewDetails = styled.div` 
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;

    svg{
        color: #ccc;
        font-size: 16px;
        cursor: pointer;
    }
`

const DirectoryCardImage = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        & img{
            width: 100%;
            height: 50px;
            border-radius: 10px;
        }
    }
    & img{
        width: 100%;
        height: 160px;
        border-radius: 7px;
        object-fit: cover;
    }

`

const ViewDirectoryInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    @media (max-width: 768px) {
        padding: 5px;
        font-size: 10px;
    }
`

const DirectoryInfoItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
        padding-left: 5px;
    }
`